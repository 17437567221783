import {listenerConstants} from '../constants/Listener.constants'
export function listener(state, action) {
    switch(action.type){
        case listenerConstants.EXPORT_ORDER_REQUEST:
            return {
                Exporting:"0",
                ExportData: action.payload
            };
        case listenerConstants.EXPORT_ORDER_SUCCESS:
            return {
                Exporting:"1",
                ExportData: action.payload
            };
        case listenerConstants.EXPORT_ORDER_FAILURE:
            return {
                Exporting:"2",
                ExportData: action.payload
            };
        case "CLEAR_LISTENER":
            return {
                Exporting:'',
                ExportData: {}
            };
        default:
            return {...state}
    }
}