import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const SnackBar = (props) => {
  const { openSnackBar, snackBarSeverity, snackBarMessage } = props;
  const { isOpenSnackBar, setIsOpenSnackBar } = openSnackBar;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenSnackBar(false);
  };
  return (
    <Snackbar
      open={isOpenSnackBar}
      autoHideDuration={6000}
      onClose={handleCloseSnackBar}
    >
      <Alert
        onClose={handleCloseSnackBar}
        severity={snackBarSeverity}
        sx={{ width: "100%" }}
      >
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
