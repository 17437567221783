import  "./Modal.scss";

const Modal = (props) => {
  const { Component, closeModal, component_props } = props;
  const { isOpenModal, setIsOpenModal } = closeModal;

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  window.onkeydown = function (event) {
    const keyCode = event.key;
    if (keyCode === "Escape") {
      setIsOpenModal(false);
    }
  };
  return (
    <>
    <div className="modal_container">
      <div className="modal_background"  onClick={handleCloseModal}></div>
        <Component
          closeModal={closeModal}
          component_props={component_props}
          />
      </div>
          </>
  );
};

export default Modal;
