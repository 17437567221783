import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Translate,
  FormatDate,
  FormatCurrency,
} from "../../../utils/lang/translate";
import { commonActions, alertActions } from "../../../redux/actions";
import XLSX from "xlsx";
class ListenerFile extends Component {
  constructor(props) {
    super(props);
  }
  componentWillReceiveProps(nextProps) {
    const { Exporting, ExportData } = nextProps;
    if (Exporting == "1") {
      this.prinXls(ExportData);
      this.props.ClearStore("CLEAR_LISTENER");
    }
    if (Exporting == "2") {
      this.props.ClearStore("CLEAR_LISTENER");
      this.props.SendAlert("error", Translate("alert", "fildexp"), "a06");
    }
  }
  prinXls(dataExport) {
    let xlsHeader = [
      Translate("product", "id"),
      Translate("orders", "customername"),
      Translate("orders", "customerphonenumber"),
      Translate("orders", "orderprice"),
      Translate("orders", "deliveryprice"),
      Translate("orders", "quantity"),
      Translate("titles", "product"),
      Translate("product", "wilaya"),
      Translate("orders", "district"),
      Translate("orders", "creationdate"),
      Translate("orders", "status"),
      Translate("statusorder", "31"),
      Translate("statusorder", "42"),
      Translate("statusorder", "41"),
      Translate("statusorder", "50"),
      Translate("orders", "abortReason"),
    ];
    let TypeFile = {
      xls: "biff8",
      xlsx: "xlsx",
      csv: "csv",
    };
    let DataFile = dataExport.data.list,
      fileType = dataExport.fileType;
    let createXLSLFormatObj = [];

    createXLSLFormatObj.push(xlsHeader);
    for (let i = 0; i < DataFile.length; i++) {
      let Element = DataFile[i];
      let products = Element.products;
      let length = products.length;
      createXLSLFormatObj.push([
        Element.display_id,
        Element.customer_name,
        Element.customer_phone,
        Element.product_price,
        Element.price,
        products[0] ? products[0].quantity : "",
        products[0] ? products[0].logistical_description : "",
        Element.wilaya, 
        Element.commune_name,
        FormatDate(Element.ordered_at),
        Translate("statusorder", Element.status),
        Element.shipped_at ? FormatDate(Element.shipped_at) : "",
        Element.postponed_to ? FormatDate(Element.postponed_to) : "",
        Element.delivered_at ? FormatDate(Element.delivered_at) : "",
        Element.aborted_at ? FormatDate(Element.aborted_at) : "",
        Element.abort_reason
          ? Translate("abortRason", Element.abort_reason)
          : "",
      ]);
      for (let l = 1; l < length; l++) {
        createXLSLFormatObj.push([
          ,
          ,
          ,
          ,
          ,
          products[l].quantity,
          products[l].logistical_description,
          ,
          ,
          ,
          ,
          ,
          ,
          ,
        ]);
      }
    }
    let todayDate = new Date().toISOString();
    let filename = "oreder_list_export_in_" + todayDate + "_file." + fileType;
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
    let wscols = [
      { width: 8.2 },
      { width: 25 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 8 },
      { width: 55 },
      { width: 15 },
      { width: 18 },
      { width: 18 },
      { width: 11 },
      { width: 18 },
      { width: 18 },
      { width: 18 },
      { width: 18 },
      { width: 30 },
    ];
    ws["!cols"] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, "Orders list");

    XLSX.writeFile(wb, filename, { bookType: TypeFile[fileType] });
    this.props.SendAlert("success", Translate("alert", "orderexscc"), "a05");
  }

  render() {
    return "";
  }
}
function mapState(state) {
  const { Exporting, ExportData } = state.listener;
  return { Exporting, ExportData };
}
const actionCreators = {
  // ClearStore: commonActions.ClearStore,
  SendAlert: alertActions.SendAlert,
};

const connectedListenerFile = connect(mapState, actionCreators)(ListenerFile);
export { connectedListenerFile as ListenerFile };
