import { ResponseHandler } from '../services';
import { alertConstants } from '../constants';
export const alertActions = {
    SendAlert
};
function SendAlert(code,text,action){
    return dispatch => {
        switch(code){
            case "success":
                dispatch(ResponseHandler.succes({txt:text,action:action},alertConstants.SEND_ALERT_SUCCESS))
                break;
            case "error":
                dispatch(ResponseHandler.failure({txt:text,action:action},alertConstants.SEND_ALERT_FAILURE))
                break;
            case "31":
                dispatch(ResponseHandler.succes({txt:text,action:action},alertConstants.SEND_ALERT_REQUEST))
                break;
            default :
                dispatch(ResponseHandler.failure('',"Remove"))
        }
    }
}