import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Translate } from "../../utils/lang/translate";
import { userActions, sessionActions } from "../../redux/actions";
import {
  CloseSvg,
  PlayListAddSvg,
  OrdersSvg,
  CallAgentSVg,
} from "../common/IconSvg";
// import InlinedElement from '../_Common/InlinedElement'
import { initGAPg, Event } from "../../utils/TrakEvent";

import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import ProfileDropDown from "./ProfileDropDown";
import ProfileLoader from "../common/ProfileLoader";
import Button from "../common/Button";

// const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
//   <span>
//     {/* Render custom icon here */}
//     {/* &#x25bc; */}
//     {children}
//   </span>
// ));

const lang = Cookies.get("lang");
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: false,
      dropNotifs: false,
      unredNotifications: [],
      unredCount: 0,
      lang: false,
      crntlang: Cookies.get("lang"),
      crntheme: Cookies.get("theme"),
      nameuser: "",
      profilepic: "",
      namestore: "",
      SdHid: false,
      is_canary: false,
    };
    this.Logout = this.Logout.bind(this);
    this.dropit = this.dropit.bind(this);
    this.DropDownRef = React.createRef();
    this.notifsRef = React.createRef();
    this.CntrlSlide = this.CntrlSlide.bind(this);
    this.handleClickOutsideDrop = this.handleClickOutsideDrop.bind(this);
  }
  componentWillReceiveProps(nextProps) {}
  componentDidMount() {
    initGAPg();
    this.props.GetProfileUser();
    // this.props.GetNotifications()
  }
  Logout() {
    this.props.Logout();
  }
  dropit(name) {
    let initval = this.state[name];
    Event("NAV_BAR", "USER_DROP_NAV_LIST", "CLICK_EVENT");
    if (initval && (name == "drop" || name == "dropNotifs")) {
      document.removeEventListener("mousedown", this.handleClickOutsideDrop);
    } else {
      document.addEventListener("mousedown", this.handleClickOutsideDrop);
    }
    this.setState({
      [name]: !initval,
    });
  }
  handleClickOutsideDrop(event) {
    if (this.DropDownRef && !this.DropDownRef.current.contains(event.target)) {
      this.setState({ drop: false });
    }
    if (this.notifsRef && !this.notifsRef.current.contains(event.target)) {
      this.setState({ dropNotifs: false });
    }
  }
  SetLang(lang) {
    if (lang != this.state.crntlang) {
      Event("NAV_BAR", "USER_CHANGE_LANG_TO_" + lang, "CLICK_EVENT");
      let date = new Date();
      date.setDate(date.getDate() + 365);
      Cookies.set("lang", lang, {
        expires: date,
      });
      window.location.reload();
    }
  }
  SetTheme(theme) {
    Event("NAV_BAR", "USE_CHANGE_THEME_BOTTUN", "CLICK_EVENT");
    if (this.state.crntheme != theme) {
      let date = new Date();
      date.setDate(date.getDate() + 365);
      Cookies.set("theme", theme, {
        expires: date,
      });
      this.setState({
        crntheme: theme,
      });
    }
  }
  CallSearch() {
    const { Searching, DataSearch, history } = this.props;
    if (Searching == "1" && DataSearch && DataSearch.filter) {
      history.push("/search" + DataSearch.filter);
    } else {
      history.push("/search?websource=" + window.location.pathname);
    }
  }
  CntrlSlide() {
    Event("NAV_BAR", "USE_HIDE_SIDE_BOTTUN", "CLICK_EVENT");
    let crnt = this.state.SdHid;
    // this.props.ClearStore("MANAGE_SIDE_BAR")
    this.setState({
      SdHid: !crnt,
    });
  }
  MarkAsRed(notif) {
    // this.props.MarkAsRead(notif.id);
    if (notif.payload) {
      try {
        let payload = JSON.parse(notif.payload.replace(/'/g, '"'));
        this.props.history.push("/products/detail/" + payload.product_id);
      } catch (err) {}
    }
  }
  MarkAsSeen(notif) {
    // this.props.MarkAllAsSeen();
    this.setState({
      unredCount: 0,
    });
  }
  EndSession() {
    this.props.EndSession();
  }
  render() {
    if (this.props.logout == "0") {
      Cookies.remove("auth");
      window.location = "/login";
    }
    let style;
    if (this.state.crntheme == "0") {
      style = (
        <style>{`:root{
                --checbxbr:#bfbfbf;
                --chekclr:#61616114;
                --bdgclInp:#F1F4F9;
                --bdgclInpTran:#323D4E90;
                --fntClr:#202224;
                --fntClrInv:#ffffff;
                --gcl:#edeef0;
                --defcl:#ffffff;
                --clbxshd:#0000000f;
                --clrbrd:#D8D8D8;
                --bdgclInpT:#F1F4F9;
                --Fnpagnt:#ADB1B8;
                --bdgopct:#0000001f;
            }`}</style>
      );
    } else {
      style = (
        <style>{`:root{
                --bdgclInp:#323D4E;
                --bdgclInpTran:#F1F4F990;
                --fntClr:#ffffff;
                --fntClrInv:#202224;
                --gcl:#1B2431;
                --defcl:#273142;
                --clbxshd:#0000000f;
                --clrbrd:#CFCFCF1D;
                --bdgclInpT:#323D4E;
                --Fnpagnt:#ADB1B8;
                --bdgopct:#ffffff1f;
                --checbxbr:#F0F0F0;
                --chekclr:#999EA7
            }`}</style>
      );
    }
    const {
      FetchingUser,
      dataProfile,
      StartingSession,
      SessionData,
      SessionInProgress,
    } = this.props;

    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        className="ClSidTh StBxSh"
        style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
      >
        <Helmet>{style}</Helmet>
        <Navbar.Brand href="/">
          <Container fluid>
            <Row>
              <Col xs="2">
                <Row>
                  <Col xs className="perfectC">
                    <img width="40" height="40" src="/logo.png" alt="" />
                  </Col>
                </Row>
              </Col>
              <Col xs="10" className="align-items-center">
                <h6 className="stbold StBle">MAYSTRO DELIVERY</h6>
                <h5 className="stbold StBle">CONFIRMATION</h5>
              </Col>
            </Row>
          </Container>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {FetchingUser === "1" &&
            //  dataProfile &&
            localStorage.getItem("_msdrvx459") === "dsq@s(-è_)9853!s*d&34" && (
              // dataProfile.is_admin  &&
              <Nav className="mx-auto justify-content-between">
                <Link to="/manage/orders" className="perfectC flex-column px-4">
                  {OrdersSvg}
                  {Translate("titles", "order")}
                </Link>
                <Link to="/import" className="perfectC flex-column px-4">
                  {PlayListAddSvg}
                  {Translate("titles", "import")}
                </Link>
                <Link to="/manage/agents" className="perfectC flex-column px-4">
                  {CallAgentSVg}
                  {Translate("titles", "agents")}
                </Link>
              </Nav>
            )}
          {/* <Link to="/performance" className="perfectC flex-column px-4">
                            {DashboardSvg}
                            {Translate("titles","performance")}
                        </Link> */}

          <Nav className="ml-auto">
            {StartingSession === "1" && SessionData && SessionInProgress && (
              <Button
                className="BdgLight"
                text={Translate("actions", "quitsession")}
                icon={CloseSvg}
                onClick={() => this.EndSession()}
              />
            )}
          </Nav>

          {FetchingUser === "0" && <ProfileLoader />}
          {FetchingUser === "1" && (
            <Nav className="perfectC">
              <Container fluid>
                <Row>
                  <Col xs="5">
                    <Row>
                      <Col xs className="perfectC">
                        <img
                          width="40"
                          height="40"
                          src={dataProfile.picture || "/logo.png"}
                          alt=""
                          style={{ borderRadius: "50%" }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="7" className="perfectC">
                    <div className="Fntcl stbold">{dataProfile.username}</div>
                  </Col>
                </Row>
              </Container>
              {/* <DropdownButton  title={""} color="none" variant="none" className="stbold" id="collasible-nav-dropdown" menuAlign="right">
                        <Dropdown.Item href="#action/3.1">Action</Dropdown.Item>
                        <Dropdown.Item href="#action/3.2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#action/3.3">Something</Dropdown.Item>
                        <Dropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </DropdownButton> */}
              <ProfileDropDown />
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

function mapState(state) {
  const { FetchingUser, dataProfile } = state.user;
  const { loginFlag, authData } = state.authentication;
  const { StartingSession, SessionData, SessionInProgress } = state.session;

  return {
    loginFlag,
    authData,
    FetchingUser,
    dataProfile,
    StartingSession,
    SessionData,
    SessionInProgress,
  };
}

const actionCreators = {
  Logout: userActions.Logout,
  GetProfileUser: userActions.GetProfileUser,
  EndSession: sessionActions.EndSession,
};

const connectedNavBar = connect(mapState, actionCreators)(NavBar);
export { connectedNavBar as NavBar };
