import React,{ Component } from 'react'
import { connect } from 'react-redux'
import {userActions} from '../../redux/actions/'
import GroupeForm from '../../components/common/GroupeForm'
import CheckBoxForm from '../../components/common/CheckBoxForm'
import Loader from '../../components/common/Loader'
import PageHeader from '../../components/common/PageHeader'
import ContainerLog from '../../components/common/ContainerLog'
import Button from '../../components/common/Button'
import {Link, Redirect} from 'react-router-dom'
import {Translate} from '../../utils/lang/translate'
import Cookies from 'js-cookie'
import { utf8_to_b64 } from '../../utils/functions'

class LoginPageAdmin extends Component{
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            remember:false,
            submited: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangecheck = this.handleChangecheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }
    componentDidMount() {
        document.title = 'Maystro Confirmation - '+Translate("titles","login");
    }
    handleChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleChangecheck(e){
        const { name, checked } = e.target;
        this.setState({ [name]: checked });
    }
    handleSubmit(e){
        e.preventDefault();
        let user = {username:this.state.username,password:this.state.password}
        this.setState({
            submited:true
        })
        this.props.Login(user,true)
    }
    render(){
        const {loginFlag,authData}=this.props
        const {submited,remember}=this.state
        if (submited && loginFlag=="1") {
            let date = new Date();
            date.setDate(date.getDate()+365);
            Cookies.set("auth",authData.auth_token,{
                expires:remember?date:""
            })
            Cookies.set(utf8_to_b64("is_admin"),utf8_to_b64("true"),{
                expires:remember?date:""
            })
           localStorage.setItem("_msdrvx459","dsq@s(-è_)9853!s*d&34")
            // return <Redirect to="/performance"/>
            return <Redirect to="/dashboard"/>
            
        }
        return(
            <ContainerLog>
                <div className="FlWd">

                    <PageHeader
                    titleHead={Translate("auths","admin")}
                    descHead={""}
                    />
                    <div className="perfectC">
                        <img src="/assets/img/lock.png" alt="" height="80"/>

                    </div>
                    {submited && loginFlag=="2" ? 
                    <div style={{color:'red',textAlign:"center"}}>
                        {Translate("error","errlogin")}
                        {<div>{authData?.response?.data?.message}</div>}
                    </div> :''}
                    <form method="post" onSubmit={this.handleSubmit} className="mt-3 ">
                        <div>
                            <GroupeForm 
                                id={'Username'}
                                name={'username'}
                                placeholder={Translate("auths","username")} 
                                text={Translate("auths","username")}   
                                type={'text'}
                                workfun={this.handleChange}
                                style={{marginTop:"10px"}}
                            />
                            <GroupeForm 
                                id={'Password'}
                                name={'password'}
                                placeholder={Translate("auths","password")}
                                text={Translate("auths","password")}    
                                type={'Password'}
                                more={<Link tabIndex="-1" to="/password/reset">
                                    {Translate("auths","forgetpassword")}
                                    </Link>}
                                workfun={this.handleChange}
                                style={{marginTop:"10px"}}
                            />

                        </div>
                        <div className="mt-3">
                            <CheckBoxForm
                                id={'RememberPasword'}
                                name={'remember'}
                                text={Translate("auths","rememberpassword")}
                                workfun={this.handleChangecheck}
                            />

                        </div>
                        <div className="SmtGrp MrAot StMarMx">
                            <Button 
                                text={Translate("auths","signin")}
                                className="BgrdPrim"
                            />
                        </div>
                    </form>
                </div>
                
                {loginFlag=="0" && 
                    <Loader styl={{borderRadius:"24px"}}/>}
            </ContainerLog>
        )
    }
}
function mapState(state) {
    const { loginFlag, authData } = state.authentication;
    return { loginFlag , authData };
}

const actionCreators = {
    Login: userActions.Login
};

const connectedLoginPageAdmin = connect(mapState, actionCreators)(LoginPageAdmin);
export { connectedLoginPageAdmin as LoginPageAdmin };