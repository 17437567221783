import { useEffect, useState } from "react";
import { t } from "src/utils/lang/translator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export interface DropDownOption {
    value: string | number;
    label: string;
}

export interface DropDownProps {
    placeholder?: string;
    options?: DropDownOption[];
    onSelectionChanged?: (value: string | number) => void
}

const DropDown = ({ placeholder='select an option', options=[], onSelectionChanged }: DropDownProps) => {

    const [option, setOption] = useState<DropDownOption | null>(null);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    useEffect(() => setIsCollapsed(true), [option]);

    const handleSelectOption = (e: React.MouseEvent<HTMLDivElement>, opt: DropDownOption) => {
        e.stopPropagation();
        setOption(opt);
        onSelectionChanged && onSelectionChanged(opt.value);
    }

    const toggleDropDown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsCollapsed(prev => !prev);
    }

    return (
        <div className="drop-down-container" onClick={toggleDropDown}>
            <span className="drop-down-text-field">{ option ? option.label : placeholder }</span>
            <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
            { !isCollapsed ? <div className={`drop-down-options`}>
                {
                    !options.length ? <div className="drop-down-option no-options">{ t('No options') }</div> :
                    options.map(opt => {
                        return (
                            <div className="drop-down-option" key={opt.value} onClick={(e) => handleSelectOption(e, opt)}>{ opt.label }</div>
                        );
                    })
                }
            </div> : '' }
        </div>
    );
};

export default DropDown;