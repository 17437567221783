import React from 'react'
import PropTypes from 'prop-types'
import { Pie } from 'react-chartjs-2';
import {PieChart_Config} from './configs'
function PieChart(props) {
    const {labels,data,title,...other}=props;
    const chartData = {
        labels,
        datasets: [
          {
            data,
            backgroundColor: PieChart_Config.dataset.backgroundColor,
            borderColor: PieChart_Config.dataset.borderColor,
            borderWidth: 1,
          },
        ],
      };
    return (
        <div className="perfectC flex-column">
            <Pie  
            data={chartData}
            />
            <h4 className="h4 my-2 StOpcVal">{title}</h4>

            
        </div>
    )
}

PieChart.propTypes = {
    labels:PropTypes.array.isRequired,
    data:PropTypes.array.isRequired,
    title:PropTypes.string,

}

export default PieChart

