export class Sorter{
    static string_sorter(a,b){
        return a.localeCompare(b);
    }
    static number_sorter(a,b){
        return a-b;
    }
    static date_sorter(a,b){
        return new Date(a)-new Date(b)
    }
}

export function get_sorter(type){
    switch(type.toLowerCase()){
        case "string": return Sorter.string_sorter;
        case "number": return Sorter.number_sorter;
        case "date": return Sorter.date_sorter;


        default: throw Error("Type comparaison not supported")
    }
}