import Dictionary from "./dictionary";
let Dict=Dictionary()
export function Translate(type,children){
    try {
      const languageDb = Dict[0]

      if (children in languageDb[type]) {
        return languageDb[type][children];
      }

      return children;
    } catch (err) {
      console.error("Error while translating::translateWord", err);

      // If something goes wrong return the word as it is.
      return children;
    }
    
}
export function FormatDate(data,hastime){
  return (new Date(data)).toLocaleDateString(Dict[2], hastime ? {year: 'numeric', month: 'short', day: 'numeric'} : {year: 'numeric', month: 'short', day: 'numeric' , hour: '2-digit', minute: '2-digit'})
}
export let FormatCurrency=new Intl.NumberFormat(Dict[1], { style: 'currency', currency: 'DZD',minimumFractionDigits: 0,maximumFractionDigits: 0 })
