import React from 'react';
export default function CheckBoxForm(props){
    return(
        <div className={props.rmMarg ? "":"StMrg"}>
            <div className="InFlx flxDrc AlgnItm">
                    <input onChange={props.workfun} type="checkbox" name={props.name} id={props.id} checked={props.check}/>
                    <label className={props.labelClass} htmlFor={props.id}>{props.text}</label>
            </div>
        </div>
    )
}