import React, { useState } from "react";
import { CloseSvg } from "../common/IconSvg";

const MODAL_STYLES = {
  position: "absolute",
  backgroundColor: "#323d4e",
  padding: "15px",
  zIndex: "1000",
  width: "50%",
  height: "80%",
  borderRadius: ".5em",
  overflowY: "auto",
};

const OVERLAY_STYLE = {
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  zIndex: "1000",
  overflowY: "auto",
};

const ModalHist = ({ open, children, setIsOpenModal }) => {
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  if (!open) return null;
  return (
    <div style={OVERLAY_STYLE}>
      <div style={MODAL_STYLES}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "10px", width: "50%" }}>History of orders</div>
          <div onClick={handleCloseModal} style={{ display: "flex", justifyContent: "flex-end", cursor: "pointer", marginBottom: "10px", width: "50%" }}>
            {CloseSvg}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ModalHist;
