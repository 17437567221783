import { createAsyncThunk, createSlice, Middleware, ThunkDispatch } from "@reduxjs/toolkit";
import { RequestService } from "src/services/api/api";
import { RootDispatch, RootState } from "../rootStore";
import { fetchWilayas } from "./wilayaSlice";

const PROFILE_DATA_API_ENDPOINT = 'confirmation/confirmation_agents/me/';

export interface Profile {
    id: string;
    user_name: string;
    full_name: string;
    sex: 'M' | 'F';
    country: number;
}

export interface ProfileState {
    profile: Profile | null;
    loading: boolean;
}

const initialState: ProfileState = {
    profile: null,
    loading: false,
};

export const fetchProfileData = createAsyncThunk('me/fetch', async (_, thunkAPI) => {
    try {
        const res =  await RequestService.getRequest<Profile>(PROFILE_DATA_API_ENDPOINT);
        await thunkAPI.dispatch(fetchWilayas(res.country));
        return res;
    } catch {
        return null;
    }
});

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProfileData.pending, (state) => {
            state.loading = true;
        }).addCase(fetchProfileData.fulfilled, (state, action) => {
            state.profile = action.payload;
            state.loading = false;
        });
    },
});

const profileReducer = profileSlice.reducer;

export default profileReducer;
