import services from "../../services/global/global.services";

const getAllAgents = async (params) => {
  let response = [{}];
  let error = [{}];

  try {
    response = await services.getAllAgents();
    if (response?.data) {
      params?.setAgents(response?.data);
    }
  } catch (err) {
    error = err;

    if (error?.response) {
      params?.setAgentsError(error?.response);
    }
  }
};

const getAllStores = async (params) => {
  let response = [{}];
  let error = [{}];

  try {
    response = await services.getAllStores();
    if (response?.data) {
      params?.setStores(response?.data);
    }
  } catch (err) {
    error = err;

    if (error?.response) {
      params?.setStoresError(error?.response);
    }
  }
};

const getAllWilayas = async (params) => {
  let response = [{}];
  let error = [{}];
  let data = {};
  try {
    response = await services.getAllWilayas();
    if (response?.data) {
      data = response?.data;
      // params?.setWilayas(response?.data);
    }
  } catch (err) {
    error = err;

    // if (error?.response && params?.setWilayaError) {
    //   params?.setWilayaError(error?.response);
    // }
  }

  return { data, error };
};

const getAllCommunes = async (params) => {
  let response = [{}];
  let error = [{}];
  let data = {};
  try {
    response = await services.getAllCommunes();
    if (response?.data) {
      // params?.setCommunes(response?.data);
      data = response?.data;
    }
  } catch (err) {
    error = err;

    // if (error?.response) {
    //   params?.setCommunesError(error?.response);
    // }
  }
  return { data, error };
};

const getCancellationReasons = async (params) => {
  let response = [];
  let error = [];
  let list = {};
  try {
    response = await services.getCancellationReasons();
    if (response?.data?.list) {
      // params?.setCancellationReasons(response?.data?.list);
      list = response?.data?.list;
    }
  } catch (err) {
    error = err;
    // if (error?.response && params?.setCancellationReasonsError) {
    //   params?.setCancellationReasonsError(error?.response);
    // }
  }

  return { list, error };
};
const globalController = {
  getAllAgents,
  getAllStores,
  getAllWilayas,
  getAllCommunes,
  getCancellationReasons,
};

export default globalController;
