import { orderConstants } from "../constants";
import {
  API_URL,
  API_URL_DEV,
  RequestService,
  ResponseHandler,
  AuthHeader,
} from "../services";
import Cookies from "js-cookie";
// const token=Cookies.get('auth')

export const orderActions = {
  ImportOrders,
  ExportOrders,
  GetOrders,
  GetProductStore,
};

function ImportOrders(orders) {
  return (dispatch) => {
    dispatch(ResponseHandler.request(orderConstants.UPLOAD_ORDERS_REQUEST));

    RequestService.PostRequest(
      "confirmation/bulk_upload_order_2b_confirmed/",
      orders,
      AuthHeader(),
      API_URL
    )
      .then((res) => {
        dispatch(
          ResponseHandler.succes(
            res.data.stats,
            orderConstants.UPLOAD_ORDERS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          ResponseHandler.failure(err, orderConstants.UPLOAD_ORDERS_FAILURE)
        );
      });
  };
}

function ExportOrders() {
  return (dispatch) => {
    dispatch(ResponseHandler.request(orderConstants.EXPORT_ORDERS_REQUEST));

    RequestService.GetRequest(
      "confirmation/orders_2b_confirmed/export/",
      AuthHeader(),
      API_URL
    )
      .then((res) => {
        dispatch(
          ResponseHandler.succes(res.data, orderConstants.EXPORT_ORDERS_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          ResponseHandler.failure(err, orderConstants.EXPORT_ORDERS_FAILURE)
        );
      });
  };
}

function GetOrders(filter, persistFilter = true) {
  return (dispatch) => {
    dispatch(ResponseHandler.request(orderConstants.GET_ORDERS_REQUEST));
    RequestService.GetRequest(
      "confirmation/orders_2b_confirmed/" + filter,
      AuthHeader(),
      API_URL
    )
      .then((res) => {
        dispatch(
          ResponseHandler.succes(
            { ressuc: res.data, filter: persistFilter ? filter : "" },
            orderConstants.GET_ORDERS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          ResponseHandler.failure(
            err.response,
            orderConstants.GET_ORDERS_FAILURE
          )
        );
      });
  };
}

// get all products store
function GetProductStore(idStore) {
  return (dispatch) => {
    dispatch(ResponseHandler.request(orderConstants.GET_PRODUCTS_REQUEST));
    RequestService.GetRequest(
      "confirmation/store_products/?id=" + idStore,
      AuthHeader(),
      API_URL
    )
      .then((res) => {
        dispatch(
          ResponseHandler.succes(res.data, orderConstants.GET_PRODUCTS_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          ResponseHandler.failure(
            err.response,
            orderConstants.GET_PRODUCTS_FAILURE
          )
        );
      });
  };
}
