import React from "react";
import TextField from "@mui/material/TextField";
import moment from "moment";

import "./PostponeOrderPopup.scss";
import { Translate } from "src/utils/lang/translate";

const PostponeOrderPopup = (props) => {
  const {
    order,
    setOrder,
    handlePostponeOrder,
    validationErrors,
    setValidationErrors,
  } = props;

  const handleSelectPostponeDate = (event) => {
    const postponeData = event.target.value || "";
    const postponeData_formated = moment(postponeData).format("YYYY-MM-DD");
    if (moment(postponeData).diff(moment()) < 0) { // if the selecetd date is in the past
      setValidationErrors((current) => {
        return {
          ...current,
          postponeData: Translate("callsession","postpone-error"),
        };
      });
      return;
    }
    setValidationErrors((current) => {
      return {
        ...current,
        postponeData: "",
      };
    });
    setOrder((current) => {
      return {
        ...current,
        postponed_to: postponeData_formated,
      };
    });
  };
  return (
    <article className="postponeOrder">
      <section className="postponeOrder_top">
        <h4>{Translate("callsession","postpone-order")}</h4>
      </section>
      <section className="postponeOrder_bottom">
        <TextField
          id="postponeDate-input"
          className="postponeDate_datePicker"
          label={Translate("callsession","postpone-to")}
          type="date"
          minDate={new Date()}
          sx={{ width: 220 }}
          value={order?.postponed_to || new Date()}
          onChange={handleSelectPostponeDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <label htmlFor="postponeDate-input">
          {validationErrors?.postponeData && validationErrors?.postponeData}
        </label>
        <button onClick={handlePostponeOrder}>{Translate("callsession","postpone-order")}</button>
      </section>
    </article>
  );
};

export default PostponeOrderPopup;
