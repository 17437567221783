import {Translate} from './lang/translate'

export const WEEKDAYS_SHORT = {
  ar: ["جمـ", "خميـ", "أربـ", "ثلثـ", "إثنـ", "أحـ", "سبـ"],
  fr: ["Sam", "Dim", "Lun", "Mar", "Mer", "Jeu", "Ven"],
};
export const MONTHS = {
  ar: [
    "جانفي",
    "فيفري",
    "مارس",
    "افريل",
    "ماي",
    "جوان",
    "جويلية",
    "اوت",
    "سبتمبر",
    "اكتوبر",
    "توفمبر",
    "ديسمبر",
  ],
  fr: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
};

export const WEEKDAYS_LONG = {
  ar: ["السبت", "الاحد", "الاثنين", "الثلثاء", "الاربعاء", "الخميس", "الجمعة"],
  fr: ["Samedi", "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
};

export const ABORT_REASONS=["1","2","3","4","5","6"].map(r=>({abortReason: r,label:Translate("abortRason",r)}))
 
