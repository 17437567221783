export enum SupportedLangs {
    EN = 'en',
    FR = 'fr',
    AR = 'ar',
}

const COOKIES: Record<string, string> = {}

export const cookies: () => Record<string, string> = () => {
    const cookiesList = document.cookie.split('; ');
    cookiesList.forEach((cookieString: string) => {
        const [key, value] = cookieString.split('=');
        COOKIES[key] = value;
    });

    return COOKIES as Readonly<typeof COOKIES>;
}

const saveCookies = () => {
    const cookiesStringList = [];
    for (const key in COOKIES) {
        cookiesStringList.push(`${key}=${COOKIES[key]}`);
    }

    document.cookie = cookiesStringList.join('; ');
}; 

export const lang = () => {
    return 'lang' in COOKIES ? COOKIES['lang'] : SupportedLangs.EN;
};

export const setLang = (lang: SupportedLangs) => {
    COOKIES['lang'] = lang;
    saveCookies();
}