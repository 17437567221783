import { callConstants } from '../constants';
export function call(state , action) {
    switch (action.type) {
        case callConstants.START_CALL_REQUEST:
            return {
                ...state,
                StartingCall:"0",
                CallData: action.payload,
            };
        case callConstants.START_CALL_SUCCESS:
            return {
                ...state,
                StartingCall:"1",
                CallData: action.payload,
            };
        case callConstants.START_CALL_FAILURE:
            return {
                ...state,
                StartingCall:"2",
                CallData: action.payload,
            };
        case callConstants.HANGUP_REQUEST:
            return {
                ...state,
                HangingUp:"0",
            };
        case callConstants.HANGUP_SUCCESS:
            return {
                ...state,
                HangingUp:"1",
            };
        case callConstants.HANGUP_FAILURE:
            return {
                ...state,
                HangingUp:"2",
            };
        
        case "CLEAR":
            return {}
        default:
            return {...state}
        }
}