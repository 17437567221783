import React from "react";
export default function ListDetailIcon(props) {
  return (
    <div className="InFlx flxDrc FlWd RlPs">
      <div className={"StMrtg2 StHgVls "}>{props.icon}</div>
      <div>
        <div className="StOpcVal">{props.title}</div>
        <div className="StSizLn stbold TxTrnf StpdVlrglt">{props.details}</div>
        <div className="InFlx flxDrc FlWd RlPs">
          <div className="StOpcVal">Updated by</div>
          <div className="StSizLn stbold TxTrnf" style={{ marginLeft: "10px" }}>
            {props.updated_by}
          </div>
        </div>
      </div>
    </div>
  );
}
