import React from 'react'
import PropTypes from 'prop-types'
import {SimpleFlechRightSvg,FlechRightSvg} from './IconSvg'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

function ActionsList(props) {
    
    return (
        <Container fluid >
            {props.items.map((item,index)=>{
                let border="0"
                if(index===props.items.length-1 && index===0) border="14px"
                else{
                    if(index===0) border="14px 14px 0px 0px"
                    if(index===props.items.length-1) border="0px 0px 14px 14px"
                }

                return (
                <Link key={index} to={item.to || ""} onClick={()=>item.onClick}>
                    <Row className={"ClSidTh StBrdTb flxDrc p-3 align-items-center "+(item.selected?"BgrdPrim":"")} style={{margin: "1px 0",borderRadius: border}}>
                       
                        <Col xs="10" className="px-2 InFlx AlgnItm flxDrc align-middle" style={{marginBottom: "1px", fontSize: "1.4rem", letterSpacing: "0.3px"}}>
                            <h5 className="align-middle">{item.title}</h5>
                            
                        </Col>
                        <Col xs ="2" className="InFlx AlgnItm flxDrc StFlxStr">
                            <div style={{maxHeight:"60%",maxWidth:"60%"}} className={"perfectC StClSvgD StBrdRd BdgLight StOpcVal"}>{props.icon || FlechRightSvg}</div>

                            

                        </Col>


                    </Row>
                </Link>)
            })}
            
        </Container>
    )
}

ActionsList.propTypes = {
    items: PropTypes.any.isRequired

}

export default ActionsList

