export const AuthRootStyle=`:root{
    --bdgclInp:#F1F4F9;
    --fntClr:#202224;
    --gcl:#edeef0;
    --defcl:#ffffff;
    --clbxshd:#0000000f;
    --clrbrd:#D8D8D8;
    --bdgclInpT:#ffffff;
    --bdgopct:#0000001f;
    --checbxbr:#bfbfbf;
    --chekclr:#61616114;
}`