import {dashboardConstants} from '../constants'
import { API_URL,AuthHeader,RequestService,ResponseHandler } from '../services';
import Cookies from 'js-cookie'
const token=Cookies.get('auth')
const agentId=Cookies.get('agentId')

export const dashboardActions = {
    GetTodayStats,
    GoToNextOrder,
    StartSession,
    EndSession
};

function GetTodayStats(){
    return dispatch => {
        dispatch(ResponseHandler.request(dashboardConstants.GET_TODAY_STATS_REQUEST))
       
        RequestService.GetRequest("stats/agent/today/"+agentId,AuthHeader(),API_URL)
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data.stats,dashboardConstants.GET_TODAY_STATS_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,dashboardConstants.GET_TODAY_STATS_FAILURE))
        })
    }
}


function GoToNextOrder(){
    return dispatch => {
        dispatch(ResponseHandler.request(dashboardConstants.GO_TO_NEXT_ORDER))
       
       
    }
}
function StartSession(){
    return dispatch => {
        dispatch(ResponseHandler.request(dashboardConstants.START_SESSION_REQUEST))
       
        RequestService.PostRequest("confirmation/session/start/",AuthHeader())
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data,dashboardConstants.START_SESSION_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,dashboardConstants.START_SESSION_FAILURE))
        })
    }
}
function EndSession(){
    return dispatch => {
        dispatch(ResponseHandler.request(dashboardConstants.END_SESSION_REQUEST))
       
        RequestService.PostRequest("confirmation/session/end/",AuthHeader())
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data,dashboardConstants.END_SESSION_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,dashboardConstants.END_SESSION_SUCCESS))
        })
    }
}
