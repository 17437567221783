import { lang, SupportedLangs } from "..";
import fprintf from "printf"

let translation: Record<string, string> | null = null;

export const loadLang = () => {

    try {
        switch(lang()) {
            case SupportedLangs.FR: {
                translation = require('./fr.json');
                break;
            }
            case SupportedLangs.AR: {
                translation = require('./ar.json');
                break;
            }
            default: {
                translation = require('./en.json');
            }
        }

        if (!translation) {
            translation = require('./en.json');
        }
    } catch {}
};

export const t = (text: string, ...args: unknown[]) => {
    if (!translation) {
        loadLang();
    }

    if (translation && text in translation) {
        return fprintf(translation[text], ...args);
    }

    return fprintf(text, ...args);
};