import React from "react";
import ContentLoader from "react-content-loader";
const TableLoader = (props) => (
  <ContentLoader speed={2} viewBox="0 0 800 260" backgroundColor="var(--bdgclInp)" foregroundColor="var(--defcl)" {...props}>
    <rect x="0" y="0" rx="0" ry="0" style={{ width: "18%" }} height="35" />
    <rect x="20%" y="0" rx="0" ry="0" style={{ width: "18%" }} height="35" />
    <rect x="40%" y="0" rx="0" ry="0" style={{ width: "18%" }} height="35" />
    <rect x="60%" y="0" rx="0" ry="0" style={{ width: "18%" }} height="35" />
    <rect x="80%" y="0" rx="0" ry="0" style={{ width: "18%" }} height="35" />
    <rect x="0" y="40" rx="0" ry="0" style={{ width: "98%" }} height="23" />
    <rect x="0" y="68" rx="0" ry="0" style={{ width: "18%" }} height="30" />
    <rect x="20%" y="68" rx="0" ry="0" style={{ width: "18%" }} height="30" />
    <rect x="40%" y="68" rx="0" ry="0" style={{ width: "18%" }} height="30" />
    <rect x="60%" y="68" rx="0" ry="0" style={{ width: "18%" }} height="30" />
    <rect x="80%" y="68" rx="0" ry="0" style={{ width: "18%" }} height="30" />
  </ContentLoader>
);

export default TableLoader;
