import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const API: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const getAuthToken = () => {
    const token = localStorage.getItem('token');
    return token;
};

API.interceptors.request.use((config) => {
    const authToken = getAuthToken();
    if (authToken) {
        config.headers = {
            ...config.headers,
            'Authorization': `Token ${authToken}`,
            'Content-Type': 'application/json'
        }
    }

    return config;
});

API.interceptors.response.use(
    (res: AxiosResponse) => res,
    (err: AxiosError) => {
        console.debug(err);
        return Promise.reject(new Error(err.message));
    },
);

const getResBody = <T>(res: AxiosResponse<T>) => res.data;

export namespace RequestService {
    export const getRequest = async <T>(apiEndpoint: string, config?: AxiosRequestConfig<T>) => {
        const res = await API.get<T>(apiEndpoint, config);
        return getResBody<T>(res);
    };
    
    export const deleteRequest = async <T>(apiEndpoint: string, config?: AxiosRequestConfig<T>) => {
        const res = await API.delete<T>(apiEndpoint, config);
        return getResBody<T>(res);
    };
    
    export const postRequest = async <ReqBodyType, ResBodyType>(
        apiEndpoint: string,
        body?: ReqBodyType,
        config?: AxiosRequestConfig<ReqBodyType>) => {
        const res = await API.post<ReqBodyType, AxiosResponse<ResBodyType>>(apiEndpoint, body, config);
        return getResBody<ResBodyType>(res);
    }
    
    export const putRequest = async <ReqBodyType, ResBodyType>(
        apiEndpoint: string,
        body?: ReqBodyType,
        config?: AxiosRequestConfig<ReqBodyType>) => {
        const res = await API.put<ReqBodyType, AxiosResponse<ResBodyType>>(apiEndpoint, body, config);
        return getResBody<ResBodyType>(res);
    }
    
    export const patchRequest = async <ReqBodyType, ResBodyType>(
        apiEndpoint: string,
        body: ReqBodyType,
        config?: AxiosRequestConfig<ReqBodyType>) => {
        const res = await API.patch<ReqBodyType, AxiosResponse<ResBodyType>>(apiEndpoint, body, config);
        return getResBody<ResBodyType>(res);
    }
}
