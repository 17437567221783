export const noMoreOrdersIllustration = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="640.657"
    height="577.179"
    viewBox="0 0 640.657 577.179"
  >
    <g id="Group_1" data-name="Group 1" transform="translate(0 0)">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M438.4,307.821c-11.016-32.93-45.035-51.232-67.414-43.745s-38.538,42.574-27.522,75.5c10.2,30.48,41.094,48.077,62.349,44.937a2.411,2.411,0,0,0-.3.864l-.65,5.421a2.442,2.442,0,0,0,3.248,2.589l10.949-3.926a2.442,2.442,0,0,0,.863-4.063l-3.947-3.773a2.415,2.415,0,0,0-.462-.332C434.48,371.081,448.623,338.368,438.4,307.821Z"
        transform="translate(-279.671 -161.411)"
        fill="#f2f2f2"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M398.524,283.594A40.17,40.17,0,0,1,416.7,335.846c-.947,2.157,2.537,3.291,3.478,1.148a43.923,43.923,0,0,0-19.623-56.449C398.49,279.451,396.443,282.491,398.524,283.594Z"
        transform="translate(-279.671 -161.411)"
        fill="#fff"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M884.4,259.821c-11.016-32.93-45.035-51.232-67.414-43.745s-38.538,42.574-27.522,75.5c10.2,30.48,41.094,48.077,62.349,44.937a2.41,2.41,0,0,0-.3.864l-.65,5.421a2.442,2.442,0,0,0,3.248,2.589l10.949-3.926a2.442,2.442,0,0,0,.863-4.063l-3.947-3.773a2.415,2.415,0,0,0-.462-.332C880.48,323.081,894.623,290.368,884.4,259.821Z"
        transform="translate(-279.671 -161.411)"
        fill="#f2f2f2"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M844.524,235.594A40.17,40.17,0,0,1,862.7,287.846c-.947,2.157,2.537,3.291,3.478,1.148a43.923,43.923,0,0,0-19.623-56.449C844.49,231.451,842.443,234.491,844.524,235.594Z"
        transform="translate(-279.671 -161.411)"
        fill="#fff"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M625.4,206.821c-11.016-32.93-45.035-51.231-67.414-43.745s-38.538,42.574-27.522,75.5c10.2,30.48,41.094,48.077,62.349,44.937a2.41,2.41,0,0,0-.3.864l-.65,5.421a2.442,2.442,0,0,0,3.248,2.589l10.949-3.926a2.442,2.442,0,0,0,.863-4.063l-3.947-3.773a2.415,2.415,0,0,0-.462-.332C621.48,270.081,635.623,237.368,625.4,206.821Z"
        transform="translate(-279.671 -161.411)"
        fill="#f2f2f2"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M585.524,182.594A40.17,40.17,0,0,1,603.7,234.846c-.947,2.157,2.537,3.291,3.478,1.148a43.923,43.923,0,0,0-19.623-56.449C585.49,178.451,583.443,181.491,585.524,182.594Z"
        transform="translate(-279.671 -161.411)"
        fill="#fff"
      />
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="2.624"
        cy="2.624"
        r="2.624"
        transform="translate(287.858 198.614)"
        fill="#ccc"
      />
      <circle
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="5.185"
        cy="5.185"
        r="5.185"
        transform="translate(279.704 298.772)"
        fill="#ccc"
      />
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="2.996"
        cy="2.996"
        r="2.996"
        transform="translate(195.097 283.186)"
        fill="#ccc"
      />
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="3.244"
        cy="3.244"
        r="3.244"
        transform="translate(240.362 233.636)"
        fill="#ccc"
      />
      <circle
        id="Ellipse_5"
        data-name="Ellipse 5"
        cx="2.624"
        cy="2.624"
        r="2.624"
        transform="translate(400.626 424.823)"
        fill="#ccc"
      />
      <circle
        id="Ellipse_6"
        data-name="Ellipse 6"
        cx="5.185"
        cy="5.185"
        r="5.185"
        transform="translate(312.116 365.737)"
        fill="#ccc"
      />
      <circle
        id="Ellipse_7"
        data-name="Ellipse 7"
        cx="2.996"
        cy="2.996"
        r="2.996"
        transform="translate(373.343 301.866)"
        fill="#ccc"
      />
      <circle
        id="Ellipse_8"
        data-name="Ellipse 8"
        cx="3.244"
        cy="3.244"
        r="3.244"
        transform="translate(392.796 365.758)"
        fill="#ccc"
      />
      <ellipse
        id="Ellipse_9"
        data-name="Ellipse 9"
        cx="6.76"
        cy="21.534"
        rx="6.76"
        ry="21.534"
        transform="translate(537.958 510.666) rotate(-39.938)"
        fill="#2f2e41"
      />
      <circle
        id="Ellipse_10"
        data-name="Ellipse 10"
        cx="43.067"
        cy="43.067"
        r="43.067"
        transform="translate(468.089 472.286)"
        fill="#2f2e41"
      />
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="13.084"
        height="23.442"
        transform="translate(516.301 549.153)"
        fill="#2f2e41"
      />
      <rect
        id="Rectangle_2"
        data-name="Rectangle 2"
        width="13.084"
        height="23.442"
        transform="translate(490.134 549.153)"
        fill="#2f2e41"
      />
      <ellipse
        id="Ellipse_11"
        data-name="Ellipse 11"
        cx="10.903"
        cy="4.089"
        rx="10.903"
        ry="4.089"
        transform="translate(507.579 568.778)"
        fill="#2f2e41"
      />
      <ellipse
        id="Ellipse_12"
        data-name="Ellipse 12"
        cx="10.903"
        cy="4.089"
        rx="10.903"
        ry="4.089"
        transform="translate(481.412 568.233)"
        fill="#2f2e41"
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M777.3,622.627c3.846-15.487,20.821-24.6,37.915-20.356s27.834,20.24,23.989,35.727-16.6,15.537-33.7,11.292S773.459,638.114,777.3,622.627Z"
        transform="translate(-279.671 -161.411)"
        fill="#3498db"
      />
      <ellipse
        id="Ellipse_13"
        data-name="Ellipse 13"
        cx="6.76"
        cy="21.534"
        rx="6.76"
        ry="21.534"
        transform="translate(440.297 492.248) rotate(-64.626)"
        fill="#2f2e41"
      />
      <circle
        id="Ellipse_14"
        data-name="Ellipse 14"
        cx="14.359"
        cy="14.359"
        r="14.359"
        transform="translate(490.359 491.907)"
        fill="#fff"
      />
      <circle
        id="Ellipse_15"
        data-name="Ellipse 15"
        cx="4.786"
        cy="4.786"
        r="4.786"
        transform="translate(494.03 496.333)"
        fill="#3f3d56"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M793.4,697.591a9.572,9.572,0,0,1-18.835,3.429h0l0-.018c-.942-5.2,3.08-7.043,8.283-7.985S792.455,692.389,793.4,697.591Z"
        transform="translate(-279.671 -161.411)"
        fill="#fff"
      />
      <ellipse
        id="Ellipse_16"
        data-name="Ellipse 16"
        cx="21.534"
        cy="6.76"
        rx="21.534"
        ry="6.76"
        transform="matrix(0.357, -0.934, 0.934, 0.357, 136.762, 500.534)"
        fill="#2f2e41"
      />
      <circle
        id="Ellipse_17"
        data-name="Ellipse 17"
        cx="43.067"
        cy="43.067"
        r="43.067"
        transform="translate(67.892 472.286)"
        fill="#2f2e41"
      />
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="13.084"
        height="23.442"
        transform="translate(91.334 549.153)"
        fill="#2f2e41"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="13.084"
        height="23.442"
        transform="translate(117.501 549.153)"
        fill="#2f2e41"
      />
      <ellipse
        id="Ellipse_18"
        data-name="Ellipse 18"
        cx="10.903"
        cy="4.089"
        rx="10.903"
        ry="4.089"
        transform="translate(91.334 568.778)"
        fill="#2f2e41"
      />
      <ellipse
        id="Ellipse_19"
        data-name="Ellipse 19"
        cx="10.903"
        cy="4.089"
        rx="10.903"
        ry="4.089"
        transform="translate(117.501 568.233)"
        fill="#2f2e41"
      />
      <circle
        id="Ellipse_20"
        data-name="Ellipse 20"
        cx="14.719"
        cy="14.719"
        r="14.719"
        transform="translate(97.33 489.731)"
        fill="#fff"
      />
      <circle
        id="Ellipse_21"
        data-name="Ellipse 21"
        cx="4.906"
        cy="4.906"
        r="4.906"
        transform="translate(107.143 499.544)"
        fill="#3f3d56"
      />
      <path
        id="Path_9"
        data-name="Path 9"
        d="M348.854,636.712c-3.477-15.574,7.639-31.31,24.829-35.149s33.944,5.675,37.422,21.249-7.915,21.318-25.1,25.156S352.331,652.286,348.854,636.712Z"
        transform="translate(-279.671 -161.411)"
        fill="#3498db"
      />
      <ellipse
        id="Ellipse_22"
        data-name="Ellipse 22"
        cx="6.76"
        cy="21.534"
        rx="6.76"
        ry="21.534"
        transform="translate(40.099 492.25) rotate(-64.626)"
        fill="#2f2e41"
      />
      <path
        id="Path_10"
        data-name="Path 10"
        d="M369.476,692.3c0,4.215,10.853,12.539,22.9,12.539s23.335-11.867,23.335-16.082-11.292.818-23.335.818S369.476,688.085,369.476,692.3Z"
        transform="translate(-279.671 -161.411)"
        fill="#fff"
      />
      <circle
        id="Ellipse_23"
        data-name="Ellipse 23"
        cx="43.067"
        cy="43.067"
        r="43.067"
        transform="translate(273.611 431.286)"
        fill="#2f2e41"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="13.084"
        height="23.442"
        transform="translate(297.053 508.153)"
        fill="#2f2e41"
      />
      <rect
        id="Rectangle_6"
        data-name="Rectangle 6"
        width="13.084"
        height="23.442"
        transform="translate(323.22 508.153)"
        fill="#2f2e41"
      />
      <ellipse
        id="Ellipse_24"
        data-name="Ellipse 24"
        cx="10.903"
        cy="4.089"
        rx="10.903"
        ry="4.089"
        transform="translate(297.053 527.778)"
        fill="#2f2e41"
      />
      <ellipse
        id="Ellipse_25"
        data-name="Ellipse 25"
        cx="10.903"
        cy="4.089"
        rx="10.903"
        ry="4.089"
        transform="translate(323.22 527.233)"
        fill="#2f2e41"
      />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M554.573,595.712c-3.477-15.574,7.639-31.31,24.829-35.149s33.944,5.675,37.422,21.249-7.915,21.318-25.1,25.156S558.05,611.286,554.573,595.712Z"
        transform="translate(-279.671 -161.411)"
        fill="#3498db"
      />
      <ellipse
        id="Ellipse_26"
        data-name="Ellipse 26"
        cx="23.892"
        cy="7.501"
        rx="23.892"
        ry="7.501"
        transform="translate(335.586 461.828) rotate(-45.022)"
        fill="#2f2e41"
      />
      <ellipse
        id="Ellipse_27"
        data-name="Ellipse 27"
        cx="7.501"
        cy="23.892"
        rx="7.501"
        ry="23.892"
        transform="translate(242.881 441.635) rotate(-44.978)"
        fill="#2f2e41"
      />
      <path
        id="Path_12"
        data-name="Path 12"
        d="M858.178,362.432c-1.43-44.16-31.675-79.016-67.555-77.855-79.853,2.366-81.8,148.8-4.707,159.32l-1.632,12.975,23.811-.771-2.468-12.843C836.64,436.4,859.474,402.468,858.178,362.432Z"
        transform="translate(-279.671 -161.411)"
        fill="#ff6584"
      />
      <path
        id="Path_13"
        data-name="Path 13"
        d="M750.914,386.915l-9,.291a146.493,146.493,0,0,1-1.65-50.973l9-.291Q744.28,362.567,750.914,386.915Z"
        transform="translate(-279.671 -161.411)"
        fill="#fff"
      />
      <rect
        id="Rectangle_7"
        data-name="Rectangle 7"
        width="27"
        height="6"
        transform="matrix(0.999, -0.032, 0.032, 0.999, 502.608, 282.52)"
        fill="#3f3d56"
      />
      <path
        id="Path_14"
        data-name="Path 14"
        d="M451.208,511.273,448.491,510,515.3,283.429l2.717,1.273Z"
        fill="#3f3d56"
      />
      <path
        id="Path_15"
        data-name="Path 15"
        d="M913.476,399.774c16.316-41.06,2.5-85.092-30.862-98.349-74.16-29.705-134.4,103.779-67.912,144.2l-6.676,11.245,22.139,8.8,2.863-12.76C864.2,459,898.683,437,913.476,399.774Z"
        transform="translate(-279.671 -161.411)"
        fill="#3498db"
      />
      <path
        id="Path_16"
        data-name="Path 16"
        d="M805.355,379.406l-8.364-3.324a146.5,146.5,0,0,1,18.834-47.4l8.364,3.324Q808.991,354.434,805.355,379.406Z"
        transform="translate(-279.671 -161.411)"
        fill="#fff"
      />
      <rect
        id="Rectangle_8"
        data-name="Rectangle 8"
        width="6"
        height="27"
        transform="translate(529.462 288.368) rotate(-68.328)"
        fill="#3f3d56"
      />
      <path
        id="Path_17"
        data-name="Path 17"
        d="M450.68,505.309l-2.607-1.484,93.278-212.868,2.607,1.483Z"
        fill="#3f3d56"
      />
      <path
        id="Path_18"
        data-name="Path 18"
        d="M406.464,365.285c-17.653-40.5-58.641-61.71-91.55-47.367-73.322,31.718-21,168.5,54.522,149.774l3.28,12.66,21.839-9.518-7.041-11.021C413.8,441.979,422.468,402.006,406.464,365.285Z"
        transform="translate(-279.671 -161.411)"
        fill="#3498db"
      />
      <path
        id="Path_19"
        data-name="Path 19"
        d="M315.849,427.687l-8.25,3.6a146.492,146.492,0,0,1-20.377-46.752l8.25-3.6Q300.684,407.516,315.849,427.687Z"
        transform="translate(-279.671 -161.411)"
        fill="#fff"
      />
      <rect
        id="Rectangle_9"
        data-name="Rectangle 9"
        width="27"
        height="6"
        transform="matrix(0.917, -0.4, 0.4, 0.917, 86.391, 307.659)"
        fill="#3f3d56"
      />
      <path
        id="Path_20"
        data-name="Path 20"
        d="M448.083,673.263h0a1.5,1.5,0,0,1-1.817-1.095L381.059,465.552l2.912-.722,65.207,206.616a1.5,1.5,0,0,1-1.095,1.817Z"
        transform="translate(-279.671 -161.411)"
        fill="#3f3d56"
      />
      <path
        id="Path_21"
        data-name="Path 21"
        d="M465.51,738.589h-157a1,1,0,0,1,0-2h157a1,1,0,0,1,0,2Z"
        transform="translate(-279.671 -161.411)"
        fill="#ccc"
      />
      <path
        id="Path_22"
        data-name="Path 22"
        d="M864.51,738.589h-157a1,1,0,0,1,0-2h157a1,1,0,0,1,0,2Z"
        transform="translate(-279.671 -161.411)"
        fill="#ccc"
      />
      <path
        id="Path_23"
        data-name="Path 23"
        d="M677.51,697.589h-157a1,1,0,0,1,0-2h157a1,1,0,0,1,0,2Z"
        transform="translate(-279.671 -161.411)"
        fill="#ccc"
      />
      <circle
        id="Ellipse_28"
        data-name="Ellipse 28"
        cx="14.719"
        cy="14.719"
        r="14.719"
        transform="translate(300.147 453.531)"
        fill="#fff"
      />
      <circle
        id="Ellipse_29"
        data-name="Ellipse 29"
        cx="4.906"
        cy="4.906"
        r="4.906"
        transform="translate(309.96 463.344)"
        fill="#3f3d56"
      />
      <path
        id="Path_24"
        data-name="Path 24"
        d="M606.222,656.883a9.572,9.572,0,0,1-18.835,3.429h0l0-.018c-.942-5.2,3.08-7.043,8.283-7.985S605.281,651.681,606.222,656.883Z"
        transform="translate(-279.671 -161.411)"
        fill="#fff"
      />
    </g>
  </svg>
);
