import OrdersServices from "../../../services/Admin/Orders.services";
const getAllOrders = async (page, filterParams, params) => {
  let response = [];
  let error = [];
  params?.setIsBackDropOpen(true);
  try {
    response = await OrdersServices.getAllOrders(page, filterParams);
    if (response) {
      params?.setOrdersList(response?.data?.results);
      params?.setOrderListResponse(response);
    }
    params?.setIsLoadingData(false);
    params?.setIsBackDropOpen(false);
  } catch (err) {
    error = err;
    if (error) {
      const errorMessage = error?.response?.data?.orders_2b_confirmed;
      if (errorMessage) {
        const errorMessage_JSON = JSON.stringify(errorMessage, null, 4);
        const errorMessage_clean = errorMessage_JSON.replace(/}|{|"|[|]/g, "");
        params?.setAssignOrdersBackendError("\n" + (errorMessage_clean || ""));
      }
    }
    params?.setIsLoadingData(false);
    params?.setIsBackDropOpen(false);
  }
};

const AssignOrdersToAgent = async (ordersList, agentId, statusId, postponed_to, query, params) => {
  let response;
  let error;
  params?.setIsBackDropOpen(true);
  try {
    response = await OrdersServices.assignOrdersToAgent(ordersList, agentId, statusId, postponed_to, query);

    if (response) {
      params?.setIsBackDropOpen(false);

      params?.setIsOpenSnackBar(true);
      params?.setSnackBarSeverity("success");
      params?.setSnackBarMessage("Orders assigned with success !");
      params?.setIsOpenAssignModal(false);
      params?.setAssignOrdersError("");
      params?.setAssignOrdersBackendError("");
    }
  } catch (err) {
    error = err;
    let errorMessage = error?.response?.data;
    let errorMessage_JSON = "";
    let errorMessage_clean = "";
    if (errorMessage?.orders_2b_confirmed) {
      // specific error message with 'orders_2b_confirmed' property
      // returned from the backend error response.
      errorMessage_JSON = JSON.stringify(errorMessage?.orders_2b_confirmed, null, 4);
      errorMessage_clean = errorMessage_JSON.replace(/}|{|"|[|]/g, "");
    }
    if (errorMessage) {
      // any other error return in the error.response.data
      // from the backend.
      errorMessage_JSON = JSON.stringify(errorMessage);
      errorMessage_clean = errorMessage_JSON.replace(/}|{|"|[|]/g, "");
    }

    params?.setIsOpenSnackBar(true);
    params?.setSnackBarSeverity("error");
    params?.setSnackBarMessage("Error while assigning orders !");
    params?.setAssignOrdersError("");
    params?.setAssignOrdersBackendError("\n" + (errorMessage_clean || ""));
    params?.setIsBackDropOpen(false);
  }
};

const getExportCSV = async (query, params) => {
  let response;

  params?.setIsBackDropOpen(true);

  try {
    response = await OrdersServices.getExportCSV(query);
    if (response) {
      params?.setExportData(response?.data);
      params?.setIsOpenSnackBar(true);
      params?.setSnackBarSeverity("success");
      params?.setSnackBarMessage("Orders exported with success !");
      params?.setIsBackDropOpen(false);
    }
  } catch (err) {
    params?.setIsOpenSnackBar(true);
    params?.setSnackBarSeverity("error");
    params?.setSnackBarMessage("Error while exporting orders !");
    params?.setIsBackDropOpen(false);
  }
};

const OrdersController = {
  getAllOrders,
  AssignOrdersToAgent,
  getExportCSV,
};

export default OrdersController;
