import React from "react"
import ContentLoader from "react-content-loader"
const ProfileLoader = (props) => (
    <ContentLoader 
    speed={2}
    height={44}
    width={175}
    viewBox="0 0 175 44"
    backgroundColor="var(--bdgclInp)"
    foregroundColor="var(--defcl)"
    {...props}
  > 
        <circle cx="22" cy="22" r="20" />
        <rect x="48" y="6" rx="2" ry="2" width="100" height="15" /> 
        <rect x="48" y="23" rx="2" ry="2" width="100" height="15" /> 
        <circle cx="163" cy="22" r="10" />
  </ContentLoader>
  )
  
  export default ProfileLoader
