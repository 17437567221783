import {statConstants} from '../constants'
import { API_URL,AuthHeader,RequestService,ResponseHandler } from '../services';
import Cookies from 'js-cookie'
const token=Cookies.get('auth')

export const statsActions = {
    GetPerformance,
    GetTodayStats
   
};


function GetPerformance(filter){
    return dispatch => {
        dispatch(ResponseHandler.request(statConstants.GET_AGENTS_PERF_REQUEST))
       
        RequestService.GetRequest("stats/performance/"+filter,AuthHeader(),API_URL)
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data.stats,statConstants.GET_AGENTS_PERF_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,statConstants.GET_AGENTS_PERF_FAILURE))
        })
    }
}
function GetTodayStats(){
    return dispatch => {
        dispatch(ResponseHandler.request(statConstants.GET_TODAY_STATS_REQUEST))
       
        RequestService.GetRequest("stats/today/",AuthHeader(),API_URL)
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data.stats,statConstants.GET_TODAY_STATS_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,statConstants.GET_TODAY_STATS_FAILURE))
        })
    }
}
