import { orderConstants } from "../constants";
export function orders(state, action) {
  switch (action.type) {
    case orderConstants.UPLOAD_ORDERS_REQUEST:
      return {
        ...state,
        UploadingOrders: "0",
        UploadRespData: action.payload,
      };
    case orderConstants.UPLOAD_ORDERS_SUCCESS:
      return {
        ...state,
        UploadingOrders: "1",
        UploadRespData: action.payload,
      };

    case orderConstants.UPLOAD_ORDERS_FAILURE:
      return {
        ...state,
        UploadingOrders: "2",
        UploadRespData: action.payload,
      };

    case orderConstants.EXPORT_ORDERS_REQUEST:
      return {
        ...state,
        ExportingOrders: "0",
        ExportData: action.payload,
      };
    case orderConstants.EXPORT_ORDERS_SUCCESS:
      return {
        ...state,
        ExportingOrders: "1",
        ExportData: action.payload,
      };

    case orderConstants.EXPORT_ORDERS_FAILURE:
      return {
        ...state,
        ExportingOrders: "2",
        ExportData: action.payload,
      };

    case orderConstants.GET_ORDERS_REQUEST:
      return {
        ...state,
        GettingOrders: "0",
        OrdersList: action.payload,
      };
    case orderConstants.GET_ORDERS_SUCCESS:
      return {
        ...state,
        GettingOrders: "1",
        OrdersList: action.payload,
      };
    case orderConstants.GET_ORDERS_FAILURE:
      return {
        ...state,
        GettingOrders: "2",
        OrdersList: action.payload,
      };
    case orderConstants.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        GettingProducts: "0",
        ProductsList: action.payload,
      };
    case orderConstants.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        GettingProducts: "1",
        ProductsList: action.payload,
      };
    case orderConstants.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        GettingProducts: "2",
        ProductsList: action.payload,
      };

    case "CLEAR":
      return {};
    default:
      return { ...state };
  }
}
