import React from 'react';
import Helmet from 'react-helmet';
import {FormatDate, Translate} from '../../utils/lang/translate'
import DayPicker, { DateUtils } from 'react-day-picker';
import {CalnderSvg} from './IconSvg'
import {MONTHS,WEEKDAYS_LONG,WEEKDAYS_SHORT} from '../../utils/constantes'
import 'react-day-picker/lib/style.css';
import Cookies from 'js-cookie'

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      from:this.props.from ? new Date(this.props.from) : null,
      to:this.props.to ? new Date(this.props.to) : null,
      showState:false
    }
    this.refDate=React.createRef();
    this.handleView=this.handleView.bind(this);
    this.handleClickOutSide=this.handleClickOutSide.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleChange=this.handleChange.bind(this)
    this.excuteDateRange=this.excuteDateRange.bind(this)
  }
  componentWillUnmount(){
    document.removeEventListener('mousedown',this.handleClickOutSide);
  }
  componentDidMount(){
    if(this.props.initDate && !(this.props.from && this.props.to)){
      this.handleChange('',"t2")
    }
  }
  handleDayClick(day) {
    if(this.state.from && this.state.to){

      this.setState({
        from:null,
        to:null
      })
      
    }
    const range = DateUtils.addDayToRange(day, this.state);
    this.excuteDateRange(range.from,range.to)
    this.setState({...range})
  }
  excuteDateRange(began,end){
    let from,to
    if(began){
      from=began.toISOString().split('T')[0]
    }
    if(end){
      to=end.toISOString().split('T')[0]
    }
    if(this.props.forceUp){
      this.props.forceUp()
    }
    this.props.changeFrom(from)
    this.props.changeTo(to)
  }
  handleView(){
    let currentView=this.state.showState
    if(currentView){
      document.removeEventListener('mousedown',this.handleClickOutSide);
    }else{
      document.addEventListener('mousedown',this.handleClickOutSide);
    }
    this.setState({
      showState:!currentView
    })
  }
  handleClickOutSide(e){
    if (this.refDate && !this.refDate.current.contains(e.target)) {
      this.handleView()
   }
  }
  handleChange(text,value){
    let from=new Date()
    let to=new Date()
    from.setDate(to.getDate()-this.ranges[value])
    if(value==="t1") to.setDate(from.getDate())
    // console.log(from.getDate(),to.getDate());
    this.setState({
      from:from,
      to:to
    })
    this.excuteDateRange(from,to)
  }
  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    let lang=Cookies.get('lang')
    return (
      <div className="StDrc" ref={this.refDate}>
        <div className="CrsPoi FlHg" onClick={this.handleView}>
          <div className="InFlx AlgnItm FrInp StBrdRdS FlHg sTbxSz" style={{padding:"5px 10px"}}>
            <span>{this.state.from ? FormatDate(new Date(this.state.from),true) : Translate('datepicker','startdate')}</span>
            <span style={{margin:"0 5px"}}>-</span>
            <span>{this.state.to ? FormatDate(new Date(this.state.to),true) : Translate('datepicker','enddate')}</span>
            <i className="InvStMrtg InFlx">{CalnderSvg}</i>
          </div>
        </div>
        <div className={(this.state.showState ? "" : "hidElem2")+" ClSidTh StBgbrds StAbs StLanNl StBxSh1"}  style={{paddingTop:"1em",zIndex:"3",top:"45px"}}>
          
          <div className="InFlx" style={{margin:"0 1em"}}>
            <div className="InFlx AlgnItm FrInp StBrdRdS" style={{width:"224px",padding:"8px 13px"}}>
              <span>{this.state.from ? FormatDate(new Date(this.state.from),true) : Translate('datepicker','startdate')}</span>
              <i className="StAutMr InFlx">{CalnderSvg}</i>
            </div>
            <div className="InFlx StAutMr FrInp StBrdRdS" style={{width:"224px",padding:"8px 13px"}}>
              <span>{this.state.to ? FormatDate(new Date(this.state.to),true) : Translate('datepicker','enddate')}</span>
              <i className="StAutMr InFlx">{CalnderSvg}</i>
            </div>
          </div>
          <DayPicker
            locale={lang}
            className="Selectable"
            months={MONTHS[lang]}
            weekdaysLong={WEEKDAYS_LONG[lang]}
            weekdaysShort={WEEKDAYS_SHORT[lang]}
            numberOfMonths={2}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
            labels={{ nextMonth: "Next Month", previousMonth: "Previous Month" }}
          />
          <Helmet>
            <style>{`
              .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                  background-color: var(--gcl) !important;
                  color:var(--fntClr) !important;
              }
              .DayPicker-Month{
                background: var(--bdgclInp);
                display:block;
                padding:15px 10px;
                border-radius:7px;
              }
              .DayPicker-Caption{
                text-align:center;
              }
              .DayPicker-Caption > div{
                font-weight: 600 !important;
                font-size:.95em;
              }
              .Selectable .DayPicker-Day {
                  border-radius: 0 !important;
              }
              .Selectable .DayPicker-Day--start {
                  border-top-left-radius: 7px !important;
                  border-bottom-left-radius: 7px !important;
              }
              .Selectable .DayPicker-Day--end {
                  border-top-right-radius: 7px !important;
                  border-bottom-right-radius: 7px !important;
              }
              .DayPicker-Day:not(.DayPicker-Day--selected):hover{
                  background-color: var(--gcl) !important;
              }
              .DayPicker-Day{
                font-size:.9em;
              }
              .DayPicker-Day:focus{
                  outline:none !important;
              }
              `}</style>
          </Helmet>
        </div>
      </div>
    );
  }
}