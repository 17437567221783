import { sessionConstants } from '../constants';
export function session(state , action) {
    switch (action.type) {
        case sessionConstants.GET_ORDER_REQUEST:
            return {
                ...state,
                GettingOrders:"0",
                OrdersData: action.payload,
            };
        case sessionConstants.GET_ORDER_SUCCESS:
            return {
                ...state,
                GettingOrders:"1",
                OrdersData: action.payload,
                CurrentIndex:0
            };
        case sessionConstants.GET_ORDER_FAILURE:
            return {
                ...state,
                GettingOrders:"2",
                OrdersData: action.payload,
                CurrentIndex:0
            };
        case sessionConstants.UPDATE_ORDER_REQUEST:
            return {
                ...state,
                UpdattingOrder:"0",
                UpdateData: action.payload,
            };
        case sessionConstants.UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                UpdattingOrder:"1",
                UpdateData: action.payload,
            };
        case sessionConstants.UPDATE_ORDER_FAILURE:
            return {
                ...state,
                UpdattingOrder:"2",
                UpdateData: action.payload,
            };
        case sessionConstants.GO_TO_NEXT_ORDER:
            return {
                ...state,
                CurrentIndex:state.CurrentIndex+1
            };

        case sessionConstants.START_SESSION_REQUEST:
            return {
                ...state,
                StartingSession:"0",
                SessionData: action.payload,
            };
        case sessionConstants.START_SESSION_SUCCESS:
            return {
                ...state,
                StartingSession:"1",
                SessionData: action.payload,
                SessionInProgress:true
            };
        case sessionConstants.START_SESSION_FAILURE:
            return {
                ...state,
                StartingSession:"2",
                SessionData: action.payload,
            };

        case sessionConstants.END_SESSION_REQUEST:
            return {
                ...state,
                EndingSession:"0",
            };
        case sessionConstants.END_SESSION_SUCCESS:
            return {
                ...state,
                StartingSession:"-1",
                EndingSession:"1",
                SessionInProgress:false
            };
        case sessionConstants.END_SESSION_FAILURE:
            return {
                ...state,
                EndingSession:"2",
                SessionData: action.payload,
            };
        case sessionConstants.CACHE_ORDER:
            return {
                ...state,
                CachedOrders:action.payload,
            };
        
        case "CLEAR":
            return {}
        default:
            return {...state}
        }
}