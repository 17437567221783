import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import XLSX from "xlsx";
import { Table } from "react-bootstrap";
import { Translate } from "../../utils/lang/translate";
import { orderActions, alertActions, commonActions } from "../../redux/actions";
import { Select } from "../../components/common/Select";
import "../../styles/Admin/ImportOrders.scss";
import Services from "../../services/Admin/ImportOrders.services";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Redirect } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const MATCHING_DICT = {
//   Confirmed: 10,
//   Cancelled: 2,
//   "En attente": 0,
//   Injoignable: 11,
//   "Call back": 11,
//   Duplicate: 8,
//   "Wilaya HS": 2,
//   "Crée dans MD": 10,
//   "Call back2": 11,
// };

const is_valid_row = (order) => {
  return Object.keys(order).filter((el) => el).length === Object.keys(order).length;
};

class ImportOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DataTable: null,
      store: null,
      agent: null,
      errstore: "",
      erragent: "",
      radio: "",
      errors: {},
      importBackendError: "",
      importError: "",
      openSnackBar: false,
      snackBarSeverity: "",
      snackBarMessage: "",
      is_showing: false,
      is_hide: true,
      is_uploaded: false,
    };

    this.submit = false;
    this.readxlsfile = this.readxlsfile.bind(this);
    this.blockEnter = this.blockEnter.bind(this);
    this.dropFile = this.dropFile.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.addAgentDefault = this.addAgentDefault.bind(this);
    this.removeAgentDefault = this.removeAgentDefault.bind(this);
    this.props.ListAgents();
    this.props.ListStores();
    this.handleCloseSnackBar = this.handleCloseSnackBar.bind(this);
  }

  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "import");
    if (this.props.UploadingOrders === "1" && this.state.is_uploaded) {
      // this.props.SendAlert("success", "Orders uploaded successfully");
      return <Redirect to="/manage/orders" />;
      //  this.props.history.push("/manage/orders");
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { UploadingOrders, UploadRespData } = nextProps;

    if (this.props.UploadingOrders === "0" && UploadingOrders === "2") {
      this.props.SendAlert("error", UploadRespData.message);
    }
    if (this.props.UploadingOrders === "0" && UploadingOrders === "1") {
      this.props.SendAlert("success", "Orders uploaded successfully");
      this.props.history.push("/manage/orders");
    }
  }
  showDropHigh(e) {
    e.preventDefault();
    e.stopPropagation();
    let dropIteam = document.getElementById("Drag_Drop_Iteam");
    dropIteam.classList.remove("hidElem");
  }
  hideDropHigh(e) {
    e.preventDefault();
    e.stopPropagation();
    let dropIteam = document.getElementById("Drag_Drop_Iteam");
    dropIteam.classList.add("hidElem");
  }
  dropFile(e) {
    e.preventDefault();
    e.stopPropagation();
    let dropIteam = document.getElementById("Drag_Drop_Iteam");
    dropIteam.classList.add("hidElem");
    let dataTra = e.dataTransfer;
    this.readxlsfile({ target: dataTra });
  }
  readxlsfile(e) {
    let reader = new FileReader();
    let bindthis = this;
    const file = e.target.files[0];
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = function (event) {
      let data = new Uint8Array(reader.result);
      // let arr = new Array();
      let arr = [];
      for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      let DataBook = arr.join("");
      let workbook = XLSX.read(DataBook, {
        type: "binary",
      });
      let first_sheet_name = workbook.SheetNames[0];

      let worksheet = workbook.Sheets[first_sheet_name];
      let JsonData = XLSX.utils.sheet_to_json(worksheet);
      bindthis.setState({
        DataTable: JsonData,
        file,
      });
    };
  }

  addAgentDefault() {
    this.setState({
      is_showing: true,
      is_hide: false,
    });
  }

  removeAgentDefault() {
    this.setState({
      is_showing: false,
      is_hide: true,
    });
  }

  handleSelect(elem, name) {
    this.setState({
      [name]: elem,
    });
  }
  ClearData() {
    this.setState({
      DataTable: null,
    });
  }
  SendOrders() {
    let TableData = document.getElementById("TbImpBo");
    if (!this.state.store) {
      this.setState({
        errstore: Translate("error", "errstore"),
      });
      document.body.scrollTo(0, 0);
      return;
    } else {
      this.setState({
        errstore: "",
      });
    }
    if (!this.state.agent && this.state.is_showing) {
      this.setState({
        erragent: Translate("error", "errstore"),
      });
      document.body.scrollTo(0, 0);
      return;
    } else {
      this.setState({
        erragent: "",
      });
    }
    if (TableData && TableData.rows) {
      let len = TableData.rows.length;
      let rows = TableData.rows;
      let Dataorders = [],
        invalid = [];
      for (let i = 0; i < len; i++) {
        let row = rows[i];
        let id = row?.cells[0]?.textContent;
        let customer_name = row?.cells[2]?.textContent;
        let customer_phone = row?.cells[3]?.textContent;
        let wilaya = row?.cells[4]?.textContent;
        let commune = row?.cells[5]?.textContent;
        let destination_text = row?.cells[6]?.textContent;
        let product_name = row?.cells[7]?.textContent;
        let quantity = Number(row?.cells[8]?.textContent);
        let product_price = Number(row?.cells[9]?.textContent);

        let order = {
          id,
          customer_name,
          customer_phone,
          wilaya,
          commune,
          destination_text,
          product_name,
          quantity,
          product_price,
          store_name: this.state.store,
        };

        if (is_valid_row(order)) {
          Dataorders.push(order);
        } else {
          invalid.push(order);
        }
      }
      this.submit = true;

      if (invalid.length)
        this.setState({
          DataTable: invalid,
          resulterr: "There are some invalid orders .Please fix them",
        });
      else {
        let formData = new FormData();
        formData.append("orders_2b_confirmed_file", this.state.file);
        {
          this.state.agent && formData.append("confirmation_agent", this.state.agent.id);
        }
        formData.append("store", this.state.store.id);

        // this.props.ImportOrders(formData);
        Services.importOrders(formData)
          .then(() => {
            this.setState({
              ...this.state,
              openSnackBar: true,
              snackBarSeverity: "success",
              snackBarMessage: "Orders assigned with success !",
              is_uploaded: true,
            });
            this.props.history.push("/manage/orders");
          })
          .catch((error) => {
            if (error?.response?.data) {
              let finalErrorMessage = "";
              let errorData = error?.response?.data;
              let errorWithIndex = [];
              if (errorData?.filter) {
                errorData?.filter((element, index) => {
                  if (Object.keys(element).length !== 0) {
                    errorWithIndex.push({
                      errorMessage: element,
                      errorLine: index + 2,
                    });
                    return element;
                  } else return null;
                });

                finalErrorMessage = (
                  <pre>
                    {errorWithIndex.map((error, index) => {
                      const errorLine = error?.errorLine;
                      const errorMessage_JSON = JSON.stringify(error.errorMessage, null, 2);
                      const errorMessage_clean = errorMessage_JSON.replace(/}|{|"|[|]/g, "");
                      return (
                        <>
                          <span style={{ color: "red" }} key={index}>
                            Line {errorLine} :{" "}
                          </span>
                          <span style={{ color: "red" }}>{errorMessage_clean}</span>
                        </>
                      );
                    })}
                  </pre>
                );
              } else if (error?.response?.data?.detail) {
                finalErrorMessage += `\n${error?.response?.data?.detail}`;
              } else {
                finalErrorMessage = <pre style={{ color: "red" }}>{error?.response?.data}</pre>;
              }
              this.setState({
                ...this.state,
                importBackendError: finalErrorMessage,
                openSnackBar: true,
                snackBarMessage: "Error while assigning orders !",
                snackBarSeverity: "error",
              });
            }
          });
      }
    }
  }

  handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      ...this.state,
      openSnackBar: false,
    });
  };
  blockEnter(e) {
    let keyCode = e.which || e.keyCode;
    keyCode === 13 && e.preventDefault();
  }
  render() {
    let styleDrop = (
      <style>{`.StBefoCn::before{
            content: "Drop here";
            font-size: 1.7em;
            font-weight: bold;
            color:var(--fntClrInv);
        }`}</style>
    );
    const { GettingAgents, AgentsList, GettingStores, StoresList } = this.props;

    return (
      <div className="adminImportOrders">
        <Snackbar open={this.state?.openSnackBar} autoHideDuration={6000} onClose={this.handleCloseSnackBar}>
          <Alert onClose={this.handleCloseSnackBar} severity={this.state?.snackBarSeverity} sx={{ width: "100%" }}>
            {this.state?.snackBarMessage}
          </Alert>
        </Snackbar>
        <h2>{Translate("titles", "upload")}</h2>
        <div fluid className="p-5 perfectC">
          <div className="ClSidTh StBrdTb SecnNivWid StBgbrds RlPs ">
            {!Array.isArray(this.state.DataTable) ? (
              <Fragment>
                <div id="Cont_File_Loader" style={{ padding: "50px 20px" }} className="BrdBlDtd RlPs" onDrop={this.dropFile} onDragLeave={this.hideDropHigh} onDragEnter={this.showDropHigh} onDragOver={this.showDropHigh}>
                  <div id="Drag_Drop_Iteam" className="hidElem">
                    <Helmet>{styleDrop}</Helmet>
                    <div className="StAbs FlHg FlWd StTpNl StRgNl" style={{ zIndex: "1" }}>
                      <div className="RlPs InFlx FlWd FlHg AlgnItm JstfCnt StbdGTrn StBefoCn"></div>
                    </div>
                  </div>
                  <form>
                    <div className="InFlx flxDrc AlgnItm JstfCnt">
                      <div className="TxtCn RlPs StMrtg5">
                        <input type="file" id="File_Import_excel" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.readxlsfile} className="hidElem HdOvrfl StAbs" />
                        <label htmlFor="File_Import_excel" className="BdgBlcl DsBlc stbold CrsPoi StBrdRd StPdsml2">
                          Click here to upload excel file, or drag and drop it
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="my-3 bg-danger">
                  <h5>{this.state.resulterr}</h5>
                </div>
                <div className="uploadOrders_form">
                  <div>
                    {this.state.is_hide && (
                      <button
                        style={{
                          backgroundColor: "#008aff",
                          borderColor: "#008aff",
                          color: "white",
                          display: "flex",
                          cursor: "pointer",

                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                        onClick={this.addAgentDefault}
                      >
                        Add Agent by default
                      </button>
                    )}

                    {this.state.is_showing && <Select label={Translate("stats", "agent")} placholder={Translate("stats", "searchagent")} search={true} Options={AgentsList} fieldShow={"full_name"} name="agent" loading={GettingAgents === "0"} value={this.state.agent} onChange={this.handleSelect} error={this.state.erragent} maxSize="200px" />}

                    {!this.state.is_hide && (
                      <button
                        style={{
                          backgroundColor: "#008aff",
                          borderColor: "#008aff",
                          color: "white",
                          display: "flex",
                          cursor: "pointer",

                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                        onClick={this.removeAgentDefault}
                      >
                        remove the Agent by default
                      </button>
                    )}
                    <Select label={Translate("orders", "storename")} placholder={Translate("orders", "storename")} search={true} Options={StoresList} fieldShow={"name"} name="store" loading={GettingStores === "0"} value={this.state.store} onChange={this.handleSelect} error={this.state.errstore} maxSize="200px" />
                  </div>

                  <div className="uploadOrders_buttonContainer">
                    <button onClick={this.SendOrders.bind(this)}>{Translate("orders", "upload")}</button>
                    {/* <div
                        className="StAutMr StClSvgD CrsPoi stbold"
                        onClick={this.SendOrders.bind(this)}
                      >
                        <BtnLg
                          className="btn-primary FlWd"
                          text={Translate("orders", "upload")}
                        />
                      </div> */}
                  </div>
                </div>
                <div style={{ color: "red" }} className="errorMessages_container">
                  <div className="backendErrorMessage">{this.state?.importBackendError}</div>
                  <div className="importErrorMessage">{this.state?.importError}</div>
                </div>

                <div id="ResultTable" className="SmMarS1">
                  {this.state?.DataTable ? (
                    <Table hover responsive>
                      <thead className="FlWd StSmlS FrBdEl Fntclinv BdgTra2">
                        <tr>
                          {Object.keys(this.state?.DataTable[0]).map((col) => (
                            <td className="borderClr">{col}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody id="TbImpBo" className="FlWd StSmlS FrBdEl Fntclinv BdgTra2">
                        {this.state.DataTable.map((element, key) => {
                          return (
                            <tr key={key}>
                              {Object.keys(this.state.DataTable[0]).map((col) => (
                                <td className="borderClr" onKeyPress={this.blockEnter} contentEditable suppressContentEditableWarning={true}>
                                  {element[col] || ""}
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <span style={{ color: "red" }}>Please upload a valid file</span>
                  )}
                </div>
                <div className=" row perfectC">
                  <h4>{this.state.file && this.state.file.name}</h4>
                </div>
                <div className=" row perfectC">
                  <b>{this.state.DataTable.length} Rows</b>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { UploadingOrders, UploadRespData } = state.orders;
  const { GettingAgents, AgentsList, GettingStores, StoresList } = state.common;
  return {
    UploadingOrders,
    UploadRespData,
    GettingAgents,
    AgentsList,
    GettingStores,
    StoresList,
  };
}

const actionCreators = {
  ImportOrders: orderActions.ImportOrders,
  ListStores: commonActions.ListStores,
  ListAgents: commonActions.ListAgents,
  SendAlert: alertActions.SendAlert,
};

const connectedImportOrdersPage = connect(mapState, actionCreators)(ImportOrderPage);
export default connectedImportOrdersPage;
