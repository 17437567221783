import React,{Component} from 'react'
import Button from './Button'
import {Link} from 'react-router-dom'
import { Image } from 'react-bootstrap';
class EmptyScreen extends Component{
    render(){
        return(
            <div className="perfectC flex-column p-5 my-5">
                <Image src={this.props.image ? this.props.image :"/assets/img/EmptySc.png"} alt="empty_results_image" fluid />
                <h3 className="h3 text-center">{this.props.title || "Empty results"}</h3>

            </div>
            
        )
    }
    
}
export { EmptyScreen };