import React from 'react';
import '../styles/RootContainer.css'
import {Col, Container,Row,} from "react-bootstrap"
export default function ContainerLog(props){
    return(
        // <div className="CnGl DlPg DlMg FxPs FlWd FlHg OvfAtoY">
        //     <div className="TbDp RlPs FlWd FlHg StSizLn SetTopbd">
        //         <div className="TbCdp RlPs TxtCn VrAlg ">
        //             <div className="StGwdt StGhgt ClSidTh MrAot StLyt RlPs StMev">
        //                 {props.children}
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <Container fluid className="FlHg perfectC authCntnr" style={{minHeight: "100vh",background:"url('/assets/img/landing.jpg') no-repeat center center fixed"}} >
            <Row className="FlHg FlWd justify-content-center align-items-center">
                <Col xs="12" sm="7" className="perfectC rounded p-5 StSizLn" style={{backgroundColor: "white"}}>
                    {props.children}
                </Col>

            </Row>


        </Container>
    )
}