import { userConstants } from "../constants";
import Cookies from "js-cookie";
import { utf8_to_b64 } from "../../utils/functions";
const initializeState = () => {
  const val = Cookies.get(utf8_to_b64("is_admin"));
  return {
    is_admin: val === utf8_to_b64("true"),
  };
};

export function user(state = initializeState(), action) {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return {
        ...state,
        FetchingUser: "0",
        dataProfile: action.payload,
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        FetchingUser: "1",
        dataProfile: action.payload,
      };
    case userConstants.GET_USER_FAILURE:
      return {
        ...state,
        FetchingUser: "2",
        dataProfile: action.payload,
      };
    case userConstants.GET_DATA_USER_REQUEST:
      return {
        ...state,
        FetchingUserData: "0",
        dataProfileUser: action.payload,
      };
    case userConstants.GET_DATA_USER_SUCCESS:
      return {
        ...state,
        FetchingUserData: "1",
        dataProfileUser: action.payload,
      };
    case userConstants.GET_DATA_USER_FAILURE:
      return {
        ...state,
        FetchingUserData: "2",
        dataProfileUser: action.payload,
      };
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loginFlag: "0",
        authData: action.payload,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loginFlag: "1",
        authData: action.payload,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginFlag: "2",
        authData: action.payload,
      };
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        registerFlag: "0",
        registerData: action.payload,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registerFlag: "1",
        registerData: action.payload,
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        registerFlag: "2",
        registerData: action.payload,
      };

    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        chngPass: "0",
        chngPassData: action.payload,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        chngPass: "1",
        chngPassData: action.payload,
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        chngPass: "2",
        chngPassData: action.payload,
      };
    case userConstants.SET_IS_CANARY:
      return {
        ...state,
        dtStoreinfo: {
          ...state.dtStoreinfo,
          is_canary: true,
        },
      };
    default:
      return { ...state };
  }
}
export function memberTeam(state, action) {
  switch (action.type) {
    case userConstants.INVITE_MEMBER_REQUEST:
      return {
        InvitingMember: "0",
        dataInvitingMember: action.payload,
      };
    case userConstants.INVITE_MEMBER_SUCCESS:
      return {
        InvitingMember: "1",
        dataInvitingMember: action.payload,
      };
    case userConstants.INVITE_MEMBER_FAILURE:
      return {
        InvitingMember: "2",
        dataInvitingMember: action.payload,
      };
    case userConstants.VERIFY_MEMBER_REQUEST:
      return {
        VeryMember: "0",
        dataVerifyMember: action.payload,
      };
    case userConstants.VERIFY_MEMBER_SUCCESS:
      return {
        VeryMember: "1",
        dataVerifyMember: action.payload,
      };
    case userConstants.VERIFY_MEMBER_FAILURE:
      return {
        VeryMember: "2",
        dataVerifyMember: action.payload,
      };
    case userConstants.CREATE_MEMBER_REQUEST:
      return {
        ...state,
        CrtingMember: "0",
        dataCreateMember: action.payload,
      };
    case userConstants.CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        CrtingMember: "1",
        dataCreateMember: action.payload,
      };
    case userConstants.CREATE_MEMBER_FAILURE:
      return {
        ...state,
        CrtingMember: "2",
        dataCreateMember: action.payload,
      };
    default:
      return { ...state };
  }
}

export function team(state, action) {
  switch (action.type) {
    case userConstants.CREATE_TEAM_REQUEST:
      return {
        CreatingTeam: "0",
        dataTeamCreate: action.payload,
      };
    case userConstants.CREATE_TEAM_SUCCESS:
      return {
        CreatingTeam: "1",
        dataTeamCreate: action.payload,
      };
    case userConstants.CREATE_TEAM_FAILURE:
      return {
        CreatingTeam: "2",
        dataTeamCreate: action.payload,
      };
    case userConstants.UPDATE_TEAM_REQUEST:
      return {
        UpdatingTeam: "0",
        dataTeamUpdate: action.payload,
      };
    case userConstants.UPDATE_TEAM_SUCCESS:
      return {
        UpdatingTeam: "1",
        dataTeamUpdate: action.payload,
      };
    case userConstants.UPDATE_TEAM_FAILURE:
      return {
        UpdatingTeam: "2",
        dataTeamUpdate: action.payload,
      };
    case userConstants.LIST_TEAM_REQUEST:
      return {
        GetingTeam: "0",
        dataListTeam: action.payload,
      };
    case userConstants.LIST_TEAM_SUCCESS:
      return {
        GetingTeam: "1",
        dataListTeam: action.payload,
      };
    case userConstants.LIST_TEAM_FAILURE:
      return {
        GetingTeam: "2",
        dataListTeam: action.payload,
      };
    case userConstants.GET_TEAM_DETAIL_REQUEST:
      return {
        GetingDTeam: "0",
        dataDListTeam: action.payload,
      };
    case userConstants.GET_TEAM_DETAIL_SUCCESS:
      return {
        GetingDTeam: "1",
        dataDListTeam: action.payload,
      };
    case userConstants.GET_TEAM_DETAIL_FAILURE:
      return {
        GetingDTeam: "2",
        dataDListTeam: action.payload,
      };
    case userConstants.DELETE_TEAM_REQUEST:
      return {
        ...state,
        DeletingTeam: "0",
      };
    case userConstants.DELETE_TEAM_SUCCESS:
      return {
        DeletingTeam: "1",
      };
    case userConstants.DELETE_TEAM_FAILURE:
      return {
        ...state,
        DeletingTeam: "2",
      };
    default:
      return { ...state };
  }
}
