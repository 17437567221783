import {callConstants} from '../constants'
import { AuthHeader, RequestService,ResponseHandler } from '../services';
import Cookies from 'js-cookie'
const token=Cookies.get('auth')

export const callActions = {
    Dial,
    HangUp
};

function Dial(phone){
    return dispatch => {
            dispatch(ResponseHandler.request(callConstants.START_CALL_REQUEST))
       
        RequestService.PostRequest("confirmation/session/call/"+phone,AuthHeader())
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data,callConstants.START_CALL_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,callConstants.START_CALL_FAILURE))
        })
    }
}
function HangUp(phone){
    return dispatch => {
            dispatch(ResponseHandler.request(callConstants.HANGUP_REQUEST))
       
        RequestService.PatchRequest("confirmation/session/call/"+phone,{HangUp: true},AuthHeader())
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data,callConstants.HANGUP_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,callConstants.HANGUP_FAILURE))
        })
    }
}
