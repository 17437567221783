import React, { Component } from "react";
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import {userActions} from '../../redux/actions'

import Cookies from 'js-cookie'

import { Translate } from "../../utils/lang/translate";
import { AccountSvg, CheckSvg,LogoutSvg,  LangSvg,FlechDwnSvg,FlagEnSvg,FlagArSvg,FlagFrSvg } from "../common/IconSvg";
const InlinedElement = (props) => {
  return (
    <div className="InFlx flxDrc FlHg BtNseti AlgnItm" style={props.style}>
      {props.first && <div className="RlPs TxtCn StMrtg">{props.first}</div>}
      {props.secnd && (
        <div className={"RlPs TxtCn " + (props.leftElm ? "StMrtg" : "")}>
          {props.secnd}
        </div>
      )}
      {props.leftElm && <div className="StAutMr InFlx">{props.leftElm}</div>}
    </div>
  );
};

export class ProfileDropDown extends Component {
    constructor(props){
        super(props)
        this.state={
            drop:false,
            lang:false,
            crntlang:Cookies.get('lang'),
            crntheme:Cookies.get('theme'),
            SdHid:false,
        }
        this.Logout=this.Logout.bind(this)
        this.dropit=this.dropit.bind(this)
        this.DropDownRef=React.createRef()
        this.handleClickOutsideDrop=this.handleClickOutsideDrop.bind(this)
    }
    SetLang(lang){
        if(lang!=this.state.crntlang){
            // Event("NAV_BAR", "USER_CHANGE_LANG_TO_"+lang, "CLICK_EVENT")
            let date = new Date();
            date.setDate(date.getDate()+365);
            Cookies.set("lang",lang,{
                expires:date
            })
            window.location.reload();
        }
    }
    dropit(name){
        let initval=this.state[name]
        if(initval && (name=="drop" || name=="dropNotifs")){
            document.removeEventListener('mousedown',this.handleClickOutsideDrop);
        }else{
            document.addEventListener('mousedown', this.handleClickOutsideDrop);
        }
        this.setState({
            [name]:!initval
        })
    }
    handleClickOutsideDrop(event) {
        if (this.DropDownRef && !this.DropDownRef.current.contains(event.target)) {
            this.setState({drop:false})
        } 
        if (this.notifsRef && !this.notifsRef.current.contains(event.target)) {
            this.setState({dropNotifs:false})
        }
    }
    Logout(){
        this.props.Logout()
    }
  render() {
    if(this.props.logout=="0"){
      Cookies.remove('auth')
      window.location='/login'
    }
    return (
      <div className="TbDp RlPs FlHg" ref={this.DropDownRef}>
        <div
          className="TbCdp RlPs TxtCn VrAlg"
          onClick={() => this.dropit("drop")}
        >
          <div className="IcnSiz CrsPoi StSvgCl StBgbrd FrInp">
            {FlechDwnSvg}
          </div>
        </div>
        <div
          className={
            "HdOvrfl  StAbs StLanNl sTvlSl StTrns Zindxsm StBgbrds BDgInpc " +
            (this.state.drop ? "" : "hidElem")
          }
        >
          <div className="RlPs StwdMaxC FlHg">
            {/* <div className="CrsPoi StBrdBt" style={{ height: "45px" }}>
              <Link to="/settings/user">
                <InlinedElement
                  first={<div className="IcnSiz">{AccountSvg}</div>}
                  secnd={<div>{Translate("profile", "editprofile")}</div>}
                />
              </Link>
            </div> */}

            <div className="CrsPoi StBrdBt RlPs">
              <div
                className="RlPs"
                style={{ height: "45px" }}
                onClick={() => this.dropit("lang")}
              >
                <InlinedElement
                  first={<div className="IcnSiz StZoom">{LangSvg}</div>}
                  secnd={<div>{Translate("titles", "language")}</div>}
                  leftElm={FlechDwnSvg}
                />
              </div>
              <div
                className={
                  this.state.lang
                    ? "lytwdp2 StTrns"
                    : "HdOvrfl StTrns lytwdp2 hidElem"
                }
              >
                <div
                  className="CrsPoi RlPs"
                  style={{ height: "40px" }}
                  onClick={() => this.SetLang("en")}
                >
                  <InlinedElement
                    first={<div className="IcnSiz">{FlagEnSvg}</div>}
                    secnd={<div>{Translate("titles", "en")}</div>}
                    leftElm={
                      this.state.crntlang == "en" && (
                        <div className="InFlx">{CheckSvg}</div>
                      )
                    }
                  />
                </div>
                <div
                  className="CrsPoi RlPs"
                  style={{ height: "40px" }}
                  onClick={() => this.SetLang("fr")}
                >
                  <InlinedElement
                    first={<div className="IcnSiz">{FlagFrSvg}</div>}
                    secnd={<div>{Translate("titles", "fr")}</div>}
                    leftElm={
                      this.state.crntlang == "fr" && (
                        <div className="InFlx">{CheckSvg}</div>
                      )
                    }
                  />
                </div>
                <div
                  className="CrsPoi RlPs"
                  style={{ height: "40px" }}
                  onClick={() => this.SetLang("ar")}
                >
                  <InlinedElement
                    first={<div className="IcnSiz">{FlagArSvg}</div>}
                    secnd={<div>{Translate("titles", "ar")}</div>}
                    leftElm={
                      this.state.crntlang == "ar" && (
                        <div className="InFlx">{CheckSvg}</div>
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="CrsPoi StBrdBt"
              style={{ height: "45px" }}
              onClick={this.Logout}
            >
              <InlinedElement
                first={<div className="IcnSiz StZoom">{LogoutSvg}</div>}
                secnd={<div>{Translate("auths", "logout")}</div>}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { logout } = state.authentication;
  return { logout};
}

const actionCreators = {
  Logout: userActions.Logout,

};

const connectedNavBar = connect(mapState, actionCreators)(ProfileDropDown);
export default connectedNavBar;