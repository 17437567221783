import { dashboardConstants } from '../constants';
export function dashboard(state , action) {
    switch (action.type) {
        
        case dashboardConstants.GET_TODAY_STATS_REQUEST:
            return {
                ...state,
                GettingSummary:"0",
                StatData: action.payload
            };
        case dashboardConstants.GET_TODAY_STATS_SUCCESS:
            return {
                ...state,
                GettingSummary:"1",
                StatData: action.payload
            };
        
        case dashboardConstants.GET_TODAY_STATS_FAILURE:
            return {
                ...state,
                GettingSummary:"2",
                StatData: action.payload
            };
        
        case "Clear_Dashboard":
            return {}
        default:
            return {...state}
        }
}