import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
export default function ContainerPage(props){
    return(
        <Container fluid className="mt-3">
            <Row>
                <Col xs className="flxDrc AlgnItm pl-5">
                {props.small_title? <h3 className="DlMg">{props.page_title}</h3> :
                        <h2 className="DlMg">{props.page_title}</h2>}
                {props.subTitle && <span className="StSmlS StOpcVal">{props.subTitle}</span>}
                </Col>

            </Row>
            <Row>
                <Col xs className="p-0">
                {props.children}
                </Col>

            </Row>

        </Container>
    )
}
// export default function ContainerPage(props){
//     return(
//         <div id="Container-root" className={"StSlyT StTrns lytWdp "+(props.withOvrl?"":"OvfAtoY HdOvFlwX")}>
//             <div>
//                 <div className="InFlx flxDrc AlgnItm FlWd RlPs">
//                     <div className="">
//                         {props.small_title? <h3 className="DlMg">{props.page_title}</h3> :
//                         <h2 className="DlMg">{props.page_title}</h2>}
//                     </div>
//                     {props.data_top &&
//                     <div className="StAutMr FlHg">
//                         {props.data_top}
//                     </div>}
//                 </div>
//             </div>
//             <div className="SmMarS2 FlWd RlPs">
//             {props.children}
//             </div>
//         </div>
//     )
// }