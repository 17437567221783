import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import './scss/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/styles/custom.css';
import App from './App';
// import {store} from './redux/store';
import reportWebVitals from './reportWebVitals';
  // Fake API Calls
import "./fake/fakeBackend"
import { rootStore } from './redux/rootStore';
import { fetchProfileData } from './redux/slices/profileSlice';

const setup = async () => {
  if (localStorage.getItem('token')) {
    await rootStore.dispatch(fetchProfileData());
  }
}

setup().then(() => {
  ReactDOM.render(
    <Provider store={ rootStore }>
        <App/>
    </Provider>
    , document.getElementById('root'));
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
