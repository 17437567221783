import { RequestService } from "src/services/api/api";

export interface Product {
    id: string;
    logistical_description: string;
    product: string;
    quantity: number;
}

export enum OrderStatus {
    PENDING = 10,
    CALLBACK = 20,
    POSTPONED = 30,
    CONFIRMED = 40,
    CANCELLED = 100,
}
export interface Order {
    id: string;
    external_id: string;
    customer_name: string;
    customer_phone: string;
    customer_phone_2: string;
    customer_address: string;
    upload_wilaya_name?: string;
    upload_commune_name?: string;
    wilaya?: number;
    commune?: number;
    display_id: number;
    details: Array<Product>;
    total_price: number;
    store: {
        id: string;
        name: string;
    },
    status?: OrderStatus;
}

/* {{{ Communes */

const COMMUNES_API_ENDPOINT = 'shared/communes/';
export interface Commune {
    id: number;
    name: string;
    wilaya: number;
    postcode: number;
    zone: number;
}

export const getCommunes = async (wilaya: number) => {
    try {
        return await RequestService.getRequest<Commune[]>(COMMUNES_API_ENDPOINT, { params: { wilaya } });
    } catch {
        return []
    }
};

/* }}} */

/* {{{ Callback Reason */

const CALLBACK_REASON_API_ENDPOINT = 'shared/callback_reasons/';
export interface CallbackReason {
    id: number;
    name: string;
}

export const getCallbackReasons = async () => {
    try {
        return await RequestService.getRequest<CallbackReason[]>(CALLBACK_REASON_API_ENDPOINT);
    } catch {
        return [];
    }
}

/* }}} */
