import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { sessionActions, dashboardActions, alertActions } from "../../../redux/actions/";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import ContainerPage from "../../../components/common/ContainerPage";
import { CallAgentSVg, CheckSvg, CloseSvg, OrdersSvg, PlayListAddSvg, WaitingSvg } from "../../../components/common/IconSvg";
import { Translate, FormatCurrency, FormatDate } from "../../../utils/lang/translate";
import { Container, Rown, Col, Row } from "react-bootstrap";
import ActionsList from "../../../components/common/ActionsList";
import { initGAPg, Event } from "../../../utils/TrakEvent";
import StatisCardLoader from "../../../components/common/StatisCardLoader";
import Cookies from "js-cookie";
import DashboardServices from "../../../services/Dashboard/DashboardServices";
import "../../../styles/Dashboard/Dashboard.scss";
const urlPropsQueryConfig = {
  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
};

const DashBoardCard = (props) => (
  <Container fluid className="ClSidTh StBrdRd pdnVldas FlHg sTbxSz StBgbrds">
    <Row>
      <Col xs="8" md="10">
        <div className="FlHg InFlx Stclmnf spctwB">
          <span className="DsBlc StSmlS StOpcVal">{props.text}</span>
          <strong style={{ marginTop: "5px" }} className="DsBlc SetBigSiz">
            {props.value}
          </strong>
        </div>
      </Col>
      <Col xs="4" md="2" className="perfectC">
        <div style={{ padding: "20%", maxHeight: "60%", maxWidth: "60%" }} className={"perfectC StClSvgD StBrdRd BdgLight"}>
          {props.icon}
        </div>
      </Col>
    </Row>
  </Container>
);

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svgDt: {},
      statistics: {},
      statisticInfos: [
        {
          // To be confirmed today
          text: Translate("dashboard", "toBeConfirmedToday"),
          key: "pendingNbr",
          value: 0,
          icon: CheckSvg,
        },
        {
          // Confirmed today
          text: Translate("dashboard", "confirmedToday"),
          key: "callbackNbr",
          value: 0,
          icon: CheckSvg,
        },
        {
          // Cancelled today
          text: Translate("dashboard", "cancelled"),
          key: "cancelledNbr",
          value: 0,
          icon: CloseSvg,
        },
        // {
        //   text: Translate("dashboard", "timespent"),
        //   key: "",
        //   value: "N/A",
        //   icon: WaitingSvg,
        // },
      ],
      orders: this.props.OrderGeting == "2" ? this.props.OrdersList.ressuc.list.results : [],

      userFullName: "",
    };
    this.crntfilter = "";
    this.dateRef = React.createRef();
    this.exctuteFilterUrl("", false, true);
  }
  static propTypes = {
    began: PropTypes.string,
    end: PropTypes.string,
    /********************** */
    onChangeBegan: PropTypes.func,
    onChangeEnd: PropTypes.func,
  };
  componentDidMount() {
    document.title = "Maystro Confirmation - " + Translate("titles", "dashboard");
    initGAPg();
    this.props.GetSummary();

    let cachedorders = localStorage.getItem("cached") ? JSON.parse(localStorage.getItem("cached")) : [];

    this.setState({ cachedorders });
    // this.exctuteFilterUrl(this.getFilterToState(this.props));

    // Get agent info
    DashboardServices.getAgentInfo()
      .then((response) => {
        if (response?.data?.stats) {
          const statistics = response?.data?.stats;
          this.setState({
            ...this.state,
            statistics: statistics,
            userFullName: response?.data?.full_name,
          });
        }
      })
      .then(() => {
        const ordersToConfirm = this.state.statistics.orders_2b_confirmed;
        const ordersConfirmed = this.state.statistics.orders_confirmed_today;
        const orderCancelled = this.state.statistics.orders_cancelled_today;

        this.setState({
          ...this.state,
          statisticInfos: [
            { ...this.state.statisticInfos[0], value: ordersToConfirm },
            { ...this.state.statisticInfos[1], value: ordersConfirmed },
            { ...this.state.statisticInfos[2], value: orderCancelled },
          ],
        });
      })
      .catch((e) => {});
  }
  componentWillReceiveProps(nextProps) {
    const { FetchingUser, GettingSummary, StatData } = nextProps;

    if (this.props.FetchingUser === "0" && FetchingUser === "2") {
      this.props.SendAlert("error", Translate("alert", "failedfetchprofile"));
    }
    if (this.props.GettingSummary === "0" && GettingSummary === "1" && StatData) {
      this.setState({
        statisticInfos: this.state.statisticInfos.map((el) => {
          return {
            ...el,
            value: StatData[el.key],
          };
        }),
      });
    }
  }
  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  exctuteFilterUrl(nextFilter, force, globalStats) {
    if (globalStats) {
      // this.props.GetStatus(nextFilter, globalStats);
      return;
    }
    let prevFilter = this.crntfilter;
    if (prevFilter != nextFilter || force) {
      this.crntfilter = nextFilter;
      // this.props.GetStatus(nextFilter);
      // this.props.GetTopProduct(nextFilter);
    }
  }
  SendDateFilter() {
    this.exctuteFilterUrl(this.getFilterToState(this.props));
  }

  render() {
    const { FetchingUser, dataProfile, StartingSession, SessionData, SessionInProgress } = this.props;
    if (StartingSession === "1" && SessionInProgress) return <Redirect to="/callsession" />;

    // Actions items
    let actions = [
      {
        title: Translate("actions", "startrcalling"),
        to: "/callsession",
        selected: true,
      },
    ];
    if (Array.isArray(this.state.cachedorders) && this.state.cachedorders.length) {
      actions.push({
        title: Translate("actions", "previousorders"),
        to: "/previousorders",
        selected: false,
      });
    }

    return (
      <div
        // page_title={`Hello,${
        //   FetchingUser === "1" && dataProfile ? dataProfile.full_name : ""
        // }`}
        // subTitle={Translate("dashboard", "subtitle")}
        className="dashboard"
      >
        <div className="dashboard_top">
          <h2>
            {Translate("dashboard", "Hello")}, {this.state.userFullName || localStorage.getItem("user_full_name")} !
          </h2>
          {/* <span>{Translate("dashboard", "subtitle")}</span> */}
        </div>

        {localStorage.getItem("_msdrvx459") ? (
          <div className="adminLadingPage">
            <Link to="/manage/orders" className="perfectC flex-column px-4">
              <div className="adminActionCard">
                <div className="adminActionCard_icon">{OrdersSvg}</div>
                <span className="adminActionCard_content">{Translate("titles", "order")}</span>
              </div>
            </Link>

            <Link to="/import" className="perfectC flex-column px-4">
              <div className="adminActionCard">
                <div className="adminActionCard_icon">{PlayListAddSvg}</div>
                <span className="adminActionCard_content">{Translate("titles", "import")}</span>
              </div>
            </Link>

            <Link to="/manage/agents" className="perfectC flex-column px-4">
              <div className="adminActionCard">
                <div className="adminActionCard_icon">{CallAgentSVg}</div>
                <span className="adminActionCard_content">{Translate("titles", "agents")}</span>
              </div>
            </Link>
          </div>
        ) : (
          <div className="FlWd">
            <Container fluid className="mt-5">
              <Row className="justify-content-between align-items-center">
                {this.state.statisticInfos.map((elem, key) => {
                  return (
                    <Link
                      key={key}
                      onClick={
                        () => null
                        // Event(
                        //   "Dashboard",
                        //   "Click in STATUS_" +
                        //     Translate("dashboard", "orders" + elem.status),
                        //   "STATUS_" + elem.status
                        // )
                      }
                      style={{
                        minWidth: "90px",
                        marginBottom: "2%",
                      }}
                      className="col-xs-12 col-md-6 "
                      to={"/orders" + "?status=" + elem.status}
                    >
                      <DashBoardCard text={elem.text} value={elem.value} icon={elem.icon} />
                    </Link>
                  );
                })}
              </Row>
            </Container>

            {/* Orders related actions list */}
            <div className="FlWd " style={{ padding: "5px" }}>
              <ActionsList items={actions} />
            </div>
            {/* Performance  and finance tabs */}
          </div>
        )}
      </div>
    );
  }
}
function mapState(state) {
  const { FetchingUser, dataProfile } = state.user;
  const { GettingSummary, StatData } = state.dashboard;
  const { StartingSession, SessionData, SessionInProgress } = state.session;

  return {
    GettingSummary,
    StatData,
    FetchingUser,
    dataProfile,
    StartingSession,
    SessionData,
    SessionInProgress,
  };
}

const actionCreators = {
  GetSummary: dashboardActions.GetTodayStats,
  StartSession: sessionActions.StartSession,
  SendAlert: alertActions.SendAlert,
};

const connectedDashboardPage = connect(mapState, actionCreators)(DashboardPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedDashboardPage);
export { QueryProps as DashboardPage };
