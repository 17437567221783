import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import thunk from "redux-thunk";

import {authentication} from './reducers/authentication.reducer'
import {dashboard} from './reducers/dashboard.reducer'
import {statistics} from './reducers/stats.reducer'
import {orders} from './reducers/order.reducer'
import {alert} from './reducers/alert.reducer'
import {common} from './reducers/common.reducer'
import {user} from './reducers/user.reducer'
import {session} from './reducers/session.reducer'
import {call} from './reducers/call.reducer'
import {listener} from './reducers/Listener.reducer'

import wilaya from "./slices/wilayaSlice";
import profile from "./slices/profileSlice";

export const rootStore = configureStore({
    reducer: {
        authentication,
        dashboard,
        statistics,
        orders,
        alert,
        common,
        user,
        session,
        call,
        listener,
        wilaya,
        profile,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export type RootState = ReturnType<typeof rootStore.getState>;
export type RootDispatch = typeof rootStore.dispatch;

export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useRootDispatch: () => RootDispatch = useDispatch;
