import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import {commonActions,statsActions,alertActions} from "../../redux/actions/"
import ContainerPage from "../../components/common/ContainerPage";
import {
  CheckSvg,CloseSvg,PendingSvg,RefreshSvg,WaitingSvg

} from "../../components/common/IconSvg";
import {
  Translate,
} from "../../utils/lang/translate";
import { initGAPg, Event } from "../../utils/TrakEvent";
import {Container,Rown,Col, Row} from "react-bootstrap"
import ActionsList from "../../components/common/ActionsList";
import StatisCardLoader from "../../components/common/StatisCardLoader";
import DatePicker from "../../components/common/DatePicker";
import { Select } from "../../components/common/Select";
import PieChart from "../../components/charts/PieChart";
const urlPropsQueryConfig = {
  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
  agent: { type: UrlQueryParamTypes.string },
};


const DashBoardCard=(props)=>(
  <Container fluid className="ClSidTh StBrdRd pdnVldas FlHg sTbxSz StBgbrds">
    <Row>
      <Col xs="8" md="10" >
        <div className="FlHg InFlx Stclmnf spctwB">
            <span className="DsBlc StSmlS StOpcVal">{props.text}</span>
            <strong style={{marginTop:"5px"}} className="DsBlc SetBigSiz">{props.value}</strong>
        </div>

      </Col>
      <Col xs="4" md="2" className="perfectC">
      <div style={{padding:"20%",maxHeight:"60%",maxWidth:"60%"}} className={"perfectC StClSvgD StBrdRd BdgLight"}>{props.icon}</div>

      </Col>
    </Row>
  </Container>)


class AgentPerfPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svgDt: {
      },
      statisticInfos:[
        {
          text: Translate("stats","confirmrate"),
          key: "confirmation_rate",
          value: 1002,
          icon: CheckSvg      
        },
        {
          text: Translate("stats","callbackrate"),
          key: "callback_rate",
          value: 100,
          icon: CloseSvg      
        },
        {
          text: Translate("stats","cancelrate"),
          key: "cancellation_rate",
          value: 100,
          icon: CloseSvg      
        }
      ],
      orders:
        this.props.OrderGeting === "2"
          ? this.props.OrdersList.ressuc.list.results
          : [],
      errors: {},
      ForceProps:false
    };
    this.crntfilter = "";
    this.dateRef = React.createRef();
    this.forceProps = this.forceProps.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.exctuteFilterUrl("", false, true);
    
  }
  static propTypes = {
    began: PropTypes.string,
    end: PropTypes.string,
    agent: PropTypes.string,
    /********************** */
    onChangeBegan: PropTypes.func,
    onChangeEnd: PropTypes.func,
    onChangeAgent: PropTypes.func,
  };
  componentDidMount() {
    document.title = "Maystro Confirmation - " + Translate("titles", "performance");
    initGAPg();
    let nextFilter=this.getFilterToState()
    this.exctuteFilterUrl(nextFilter,true)

    this.props.ListAgents()

    this.props.GetTodayStats()
    // this.exctuteFilterUrl(this.getFilterToState(this.props));
  }
  componentWillReceiveProps(nextProps) {
    const {FetchingUser,GettingAgentsPerf,perfData}=nextProps
    
    if(this.props.FetchingUser==="0" && FetchingUser==="2"){
      this.props.SendAlert("error", Translate("alert", "failedfetchprofile"));
    }
    if(this.props.GettingAgentsPerf==="0" && GettingAgentsPerf==="1" && perfData){
      this.setState({
        statisticInfos: this.state.statisticInfos.map(el=>{
          return {
            ...el,
            value: perfData[el.key] 
          }
        })
      })
    }

    if(this.resFilter || this.state.ForceProps){          
      this.resFilter=false
      this.setState({
        ForceProps:false
      })
      this.exctuteFilterUrl(this.getFilterToState(nextProps))
    }
    
  }
  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  exctuteFilterUrl(nextFilter, force, globalStats) {
    if (globalStats) {
      // this.props.GetStatus(nextFilter, globalStats);
      return;
    }
    let prevFilter = this.crntfilter;
    if (prevFilter != nextFilter || force) {
      this.crntfilter = nextFilter;
      this.props.GetPerformance(nextFilter);
      // this.props.GetTopProduct(nextFilter);
    }
  }

  forceProps(){
    this.setState({
      ForceProps:true
    })
  }
  
  SendDateFilter() {
    this.exctuteFilterUrl(this.getFilterToState(this.props));
  }
  handleSelect(elem,name){
    this.setState({
      [name]:elem
       
    })
    this.props.onChangeAgent(elem.id)
    this.forceProps()

  }

  
  render() {
    const {
      GettingAgents,AgentsList,
      GettingAgentsPerf,perfData,
      GettingTodayStats,StatData
      

     
    } = this.props;

    const chartData=GettingAgents==="1" && perfData ? [
      perfData.confirmedNbr,
      perfData.callbackNbr,
      perfData.cancelledNbr,
    ]: []
    
    
    return (
      <ContainerPage
        page_title={Translate("titles","performance")}
        subTitle={Translate("stats","subtitle")}
      >
        <div className="FlWd">
         

               {/* Today summary  */}
            <Container fluid className="mt-5">
              <h4 className="h4">{Translate("stats","todaystats")}</h4>
              {GettingTodayStats==="0" && 
              <Row className="justify-content-center align-items-center">
                {[1,2,3,4].map(el=>(<StatisCardLoader key={el}/>))}
              </Row>}
              {GettingTodayStats==="1" && StatData &&
              <Row className="justify-content-center align-items-center">
       
                      <div
                        style={{
                          minWidth: "90px",
                          marginBottom: "2%",
                        }}
                        className="col-xs-12 col-md-4"
                       
                      >
                        <DashBoardCard 
                        text={Translate("dashboard","pending")}
                        value={StatData.pendingNbr}
                        icon={WaitingSvg}
                        />
                      </div>
                      <div
                        style={{
                          minWidth: "90px",
                          marginBottom: "2%",
                        }}
                        className="col-xs-12 col-md-4"
                       
                      >
                        <DashBoardCard 
                        text={Translate("dashboard","confirmed")}
                        value={StatData.confirmedNbr}
                        icon={CheckSvg}
                        />
                      </div>
                      <div
                        style={{
                          minWidth: "90px",
                          marginBottom: "2%",
                        }}
                        className="col-xs-12 col-md-4"
                       
                      >
                        <DashBoardCard 
                        text={Translate("dashboard","callback")}
                        value={StatData.callbackNbr}
                        icon={RefreshSvg}
                        />
                      </div>
                      <div
                        style={{
                          minWidth: "90px",
                          marginBottom: "2%",
                        }}
                        className="col-xs-12 col-md-4"
                       
                      >
                        <DashBoardCard 
                        text={Translate("dashboard","cancelled")}
                        value={StatData.cancelledNbr}
                        icon={CloseSvg}
                        />
                      </div>
                 
              </Row>}

              
            </Container>
            <Container fluid className="mt-5">
              <h4 className="h4">{Translate("stats","globalstats")}</h4>
              
              <Row className="mb-4 justify-content-between align-items-center">
                <Col xs={12} md={4} >
                  <Select
                      label={Translate("stats","agent")}
                      placholder={Translate("stats","searchagent")}
                      search={true}
                      Options={AgentsList}
                      fieldShow={"username"}
                      name="agent"
                      loading={GettingAgents==="0"}
                      value={(Array.isArray(AgentsList) && AgentsList.find(el=>el.id+""===this.props.agent+"")) || null}
                      onChange={this.handleSelect}
                      error={this.state.errors.erragent}
                      maxSize="200px"
                  />

                </Col>
                <Col xs={12} md={4}>
                <label className="StmpB3 DsBlc">{Translate("datepicker","daterange")}</label>
                  <DatePicker 
                  forceUp={this.forceProps} 
                  from={this.props.began} to={this.props.end} 
                  changeFrom={this.props.onChangeBegan} changeTo={this.props.onChangeEnd} 
                  chnageDate={this.props.onChangeDate}
                  />
                </Col>
              </Row>
                  {/* Charts section */}
              <Row className="my-4 justify-content-center align-items-center">
                
                <PieChart 
                title="% of different statuses"
                labels={this.state.statisticInfos.map((elem, key) =>elem.text)}
                data={chartData}


                />

              </Row>
              <Row className="justify-content-between align-items-center">

                {this.state.statisticInfos.map((elem, key) => {
                    
                    return (
                      <div
                        key={key}
                        style={{
                          minWidth: "90px",
                          marginBottom: "2%",
                        }}
                        className="col-xs-12 col-md-4"
                       
                      >
                        <DashBoardCard 
                        text={elem.text}
                        value={elem.value}
                        icon={elem.icon}
                        />
                      </div>
                    );
                  })}
              </Row>

              
            </Container>
            
            

          </div>
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const {FetchingUser,dataProfile}=state.user
  const {GettingAgentsPerf,perfData,GettingTodayStats,StatData}=state.statistics
  const {GettingAgents,AgentsList}=state.common
 
  return { GettingAgentsPerf,perfData,GettingTodayStats,StatData,FetchingUser,dataProfile,GettingAgents,AgentsList};
   
}

const actionCreators = {
  GetPerformance:statsActions.GetPerformance,
  GetTodayStats:statsActions.GetTodayStats,
  ListAgents: commonActions.ListAgents,
  SendAlert: alertActions.SendAlert,
};

const connectedAgentPerfPage = connect(mapState, actionCreators)(AgentPerfPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedAgentPerfPage);
export { QueryProps as AgentPerfPage };
