export const commonConstants = {
  GET_WILAYAS_SUCCESS: "GET_WILAYAS_SUCCESS",
  GET_WILAYAS_FAILURE: "GET_WILAYAS_FAILURE",
  GET_WILAYAS_REQUEST: "GET_WILAYAS_REQUEST",

  GET_COMMUNE_SUCCESS: "GET_COMMUNE_SUCCESS",
  GET_COMMUNE_FAILURE: "GET_COMMUNE_FAILURE",
  GET_COMMUNE_REQUEST: "GET_COMMUNE_REQUEST",

  GET_WAREHOUSE_SUCCESS: "GET_WAREHOUSE_SUCCESS",
  GET_WAREHOUSE_FAILURE: "GET_WAREHOUSE_FAILURE",
  GET_WAREHOUSE_REQUEST: "GET_WAREHOUSE_REQUEST",

  GET_DELIVERY_SUCCESS: "GET_DELIVERY_SUCCESS",
  GET_DELIVERY_FAILURE: "GET_DELIVERY_FAILURE",
  GET_DELIVERY_REQUEST: "GET_DELIVERY_REQUEST",

  GET_STORES_LIST_SUCCESS: "GET_STORES_LIST_SUCCESS",
  GET_STORES_LIST_FAILURE: "GET_STORES_LIST_FAILURE",
  GET_STORES_LIST_REQUEST: "GET_STORES_LIST_REQUEST",

  GET_AGENTS_LIST_SUCCESS: "GET_AGENTS_LIST_SUCCESS",
  GET_AGENTS_LIST_FAILURE: "GET_AGENTS_LIST_FAILURE",
  GET_AGENTS_LIST_REQUEST: "GET_AGENTS_LIST_REQUEST",

  PUT_AGENTS_SUCCESS: "PUT_AGENTS_SUCCESS",
  PUT_AGENTS_FAILURE: "PUT_AGENTS_FAILURE",
  PUT_AGENTS_REQUEST: "PUT_AGENTS_REQUEST",
};
