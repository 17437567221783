import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import './CancelOrderPopup.scss'
import { Translate } from "src/utils/lang/translate";
const CancelOrderPopup = (props) => {
  const {
    cancellationReasons,
    setOrder,
    handleCancelOrder,
    validationErrors,
  } = props;

  const handleSelectCancellationReason = (event,selected)=>{
    const newReason = selected?.value || '';
    setOrder(current=>{
        return{
            ...current,
            cancellation_reason :newReason
        }
    })
  }
  return (
    <article className="cancelOrderPopup">
      <section className="cancelOrderPopup_top">
        <h4>{Translate("callsession","cancel-order")}</h4>
    
      </section>
      <section className="cancelOrderPopup_bottom">
        <Autocomplete
          id="cancellationReason-input"
          className="input_autocomplete"
          disablePortal
          options={cancellationReasons.map((reason, index) => {
            return {
              value: reason?.id,
              label: reason?.name,
            };
          })}
          renderInput={(params) => (
            <TextField {...params} label={Translate("callsession","cancellation-reason")} />
          )}
          onChange={handleSelectCancellationReason}
        />
        
        <label htmlFor="cancellationReason-input">{validationErrors?.cancelReason}</label>
        <button onClick={handleCancelOrder}>{Translate("callsession","cancel-order")}</button>
      </section>
         
    </article>
  );
};

export default CancelOrderPopup;
