import {sessionConstants} from '../constants'
import { API_URL,AuthHeader,RequestService,ResponseHandler } from '../services';
import Cookies from 'js-cookie'
const token=Cookies.get('auth')


export const sessionActions = {
    GetOrder,
    UpdateOrder,
    CacheOrder,
    GoToNextOrder,
    StartSession,
    EndSession
};

function GetOrder(){
    return dispatch => {
        dispatch(ResponseHandler.request(sessionConstants.GET_ORDER_REQUEST))
       
        RequestService.GetRequest("confirmation/agents/orders_2b_confirmed/next/",AuthHeader(),API_URL)
        .then(res=>{
                dispatch(ResponseHandler.succes(res.status===204? []:[res.data],sessionConstants.GET_ORDER_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,sessionConstants.GET_ORDER_FAILURE))
        })
    }
}

function UpdateOrder(order,status){
    let url;
    switch(status){
      case "callback": 
        url=`confirmation/agents/orders_2b_confirmed/${order.id}/callback/`;
        break;
      case "confirm": url=`confirmation/agents/orders_2b_confirmed/${order.id}/confirm/`;
        break;
      case "cancel": url=`confirmation/agents/orders_2b_confirmed/${order.id}/cancel/`;
        break;
      case "postpone": url=`confirmation/agents/orders_2b_confirmed/${order.id}/postpone/`;
        break;
      default: url="";
    }
    return dispatch => {
        dispatch(ResponseHandler.request(sessionConstants.UPDATE_ORDER_REQUEST))
       
        RequestService.PostRequest(url,order,AuthHeader(),API_URL)
        // RequestService.PutRequest(`pending/${order.jobId}`,order,AuthHeader(),API_URL)
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data,sessionConstants.UPDATE_ORDER_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,sessionConstants.UPDATE_ORDER_FAILURE))
        })
    }
}
function CacheOrder(order){
    let cachedorders=localStorage.getItem("cached")?JSON.parse(localStorage.getItem("cached")) : [];
    let appended=cachedorders.find(el=>el.id===order.id)?cachedorders:[...cachedorders,order]
    localStorage.setItem("cached",JSON.stringify(appended))
    return dispatch => {
        dispatch({
            type: sessionConstants.CACHE_ORDER,
            paypload: appended
        })
       
        
    }
}

function GoToNextOrder(){
    return dispatch => {
        dispatch(ResponseHandler.request(sessionConstants.GO_TO_NEXT_ORDER))
       
       
    }
}
function StartSession(){
    return dispatch => {
        dispatch(ResponseHandler.request(sessionConstants.START_SESSION_REQUEST))
       
        RequestService.PostRequest("confirmation/session/start/",AuthHeader())
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data,sessionConstants.START_SESSION_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,sessionConstants.START_SESSION_FAILURE))
        })
    }
}
function EndSession(){
    return dispatch => {
        dispatch(ResponseHandler.request(sessionConstants.END_SESSION_REQUEST))
       
        RequestService.PostRequest("confirmation/session/end/",AuthHeader())
        .then(res=>{
                dispatch(ResponseHandler.succes(res.data,sessionConstants.END_SESSION_SUCCESS))

        })
        .catch(err=>{
            dispatch(ResponseHandler.failure(err,sessionConstants.END_SESSION_SUCCESS))
        })
    }
}
