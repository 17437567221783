import React from "react"
import ContentLoader from "react-content-loader"
const StatisCardLoader = (props) => (
    <div className="ClSidTh StBrdRd pdnVldas FlHg StBrdTb InFlx AlgnItm FlWd sTbxSz StBgbrds" style={{width:"16.2%"}}>
        <ContentLoader 
        speed={2}
        height={70}
        width={140}
        viewBox="0 0 145 70"
        backgroundColor="var(--bdgclInp)"
        foregroundColor="var(--defcl)"
        {...props}
    > 
        <rect x="4" y="9" rx="0" ry="0" width="95" height="25" />
        <rect x="4" y="40" rx="0" ry="0" width="80" height="25" />
        <rect x="110" y="25" rx="5" ry="5" width="35" height="35" />
    </ContentLoader>
  </div>
  )
  
  export default StatisCardLoader

 