import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import Select from "react-select";
// import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import { commonActions, userActions, alertActions } from "../../../redux/actions/";
// import ContainerPage from "../../../components/common/ContainerPage";
import { Translate } from "../../../utils/lang/translate";
import { initGAPg } from "../../../utils/TrakEvent";
import { Row, Form, Col, Button } from "react-bootstrap";
import "../../../styles/Admin/AddAgent.scss";
import globalServices from "../../../services/global/global.services";
import { Doughnut } from "react-chartjs-2";
import { EditSvg, RefreshSvg } from "src/components/common/IconSvg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import { Redirect } from "react-router-dom";
import Loader from "src/components/common/Loader";
import TableLoader from "./TableLoader";

const urlPropsQueryConfig = {
  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
  agent: { type: UrlQueryParamTypes.string },
};
const countrySelect = [
  { id: 1, name: "Algeria Agent" },
  { id: 2, name: "Tunisia Agent" },
];

const isActiveSelect = [
  { id: "True", name: "Active" },
  { id: "False", name: "Inactive" },
];

class AddAgentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent: {
        full_name: "",
        username: "",
        password: "",
        sex: "M",
        orders_limit: 50,
      },
      country: 1,
      errors: {},
      ForceProps: false,
      agentList: [],
      agentSearch: "",
      addAgent: false,
      editAgent: false,
      idAgent: {},
      hover: false,
      agentCourant: {},
      selectedAgent_filter: "",
      selectActive_filter: "",
      filterParams: "",
      hide_filter: true,
      is_loading: false,
      is_loading_table: false,
    };

    this.handleFilterAgentList = this.handleFilterAgentList.bind(this);
    this.filterAgents = this.filterAgents.bind(this);
    this.forceProps = this.forceProps.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeModif = this.handleChangeModif.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitModif = this.handleSubmitModif.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.handleSelectActive_filter = this.handleSelectActive_filter.bind(this);
    this.handleSelectAgent_filter = this.handleSelectAgent_filter.bind(this);
    this.onSubmit_filter = this.onSubmit_filter.bind(this);
  }

  static propTypes = {};
  componentDidMount() {
    document.title = "Maystro Confirmation - " + Translate("titles", "agents");
    initGAPg();
    this.props.ListAgents();
    this.setState({ is_loading_table: true });
    globalServices
      .getAllAgents("?active=True")
      .then((response) => {
        if (response?.data)
          this.setState({
            ...this.state,
            agentList: response?.data,
          });
        this.setState({ is_loading_table: false });
      })
      .catch((error) => {});
  }
  componentWillReceiveProps(nextProps) {
    const { registerFlag } = nextProps;

    if (this.props.registerFlag === "0" && registerFlag === "2") {
      this.props.SendAlert("error", Translate("alert", "failedcreateagent"));
    }
    if (this.props.registerFlag === "0" && registerFlag === "1") {
      this.props.SendAlert("success", Translate("alert", "successcreateagent"));
      this.props.ListAgents();
    }
  }

  forceProps() {
    this.setState({
      ForceProps: true,
    });
  }

  handleSelectAgent_filter(selected) {
    this.setState({ selectedAgent_filter: selected?.value || "" });
  }

  handleSelectActive_filter(selected) {
    this.setState({ selectActive_filter: selected?.value || "" });
  }

  handleChange(e) {
    const { agent } = this.state;
    this.setState({
      agent: {
        ...agent,
        [e.target.name]: e.target.value,
      },
    });
  }
  handleChangeModif(e) {
    const { idAgent } = this.state;
    this.setState({
      idAgent: {
        ...idAgent,
        [e.target.name]: e.target.value,
      },
    });
  }
  onChangeCountry(e) {
    this.setState({
      country: e?.target?.value,
    });
  }

  handleSelect(elem, name) {
    this.setState({
      [name]: elem,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    let user = {
      ...this.state.agent,
      country: this.state.country,
      orders_limit: Number(this.state.agent.orders_limit),
    };

    this.props.RegisterAgent(user);
  }

  handleSubmitModif(e) {
    e.preventDefault();
    e.stopPropagation();

    let agent = {
      ...this.state?.idAgent,
      country: this.state.country,
      orders_limit: Number(this.state?.idAgent.orders_limit),
      is_active: this.state?.idAgent.is_active ? this.state?.idAgent.is_active : "True",
      supervisor: this?.state?.idAgent.supervisor ? this?.state?.idAgent.supervisor : null,
    };
    delete agent["id"];
    delete agent["confirmation_rate"];
    delete agent["delivery_rate"];
    delete agent["is_allowed2auto_receive"];
    delete agent["last_auto_receive_at"];
    delete agent["stats"];

    this.props.ModifierAgents(this.state?.idAgent.id, agent);
  }

  handleFilterAgentList(event) {
    this.setState({
      ...this.state,
      agentSearch: event?.target?.value,
    });
  }
  filterAgents(agent) {
    if (this?.state?.agentSearch === "") return agent;
    else if (agent?.full_name?.toLowerCase().includes(this?.state?.agentSearch.toLowerCase()) || agent?.username?.toLowerCase().includes(this?.state?.agentSearch?.toLowerCase())) return agent;
    else return null;
  }
  onHover() {
    this.setState({ hover: true });
  }

  onLeave() {
    this.setState({ hover: false });
  }

  onSubmit_filter() {
    const country = this.state.selectedAgent_filter || "";
    const isActive = this.state.selectActive_filter || "";

    const params = "?country=" + country + "&active=" + isActive;
    this.setState({ is_loading: true });
    globalServices
      .getAllAgents(params)
      .then((response) => {
        if (response?.data)
          this.setState({
            ...this.state,
            agentList: response?.data,
          });
        this.setState({ is_loading: false });
      })
      .catch((error) => {});
  }

  render() {
    if (this.props.PutAgents === "1") {
      window.location.reload(false);
    }

    const initialValues_filter = {};

    return (
      <div className="admin_addAgent">
        {(this.props.PutAgents === "0" || this.state.is_loading) && (
          <div id="Container-root" className="StSlyT RlPs StTrns lytWdp OvfAtoY HdOvFlwX">
            <Loader />
          </div>
        )}
        <div style={{ width: "100%" }}>
          <h2>{Translate("titles", "agents")}</h2>
          <div onMouseEnter={this.onHover} onMouseLeave={this.onLeave} role="button" className="button_add_agent">
            {!this.state.addAgent &&
              !this.state.editAgent &&
              (this.state.hover ? (
                <div onClick={() => this.setState({ addAgent: true, hide_filter: false })} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ marginRight: "10px" }}>Ajouter un Agent</div> <AddIcon />
                </div>
              ) : (
                <AddIcon onClick={() => this.setState({ addAgent: true, hide_filter: false })} />
              ))}
            {(this.state.addAgent || this.state.editAgent) &&
              (this.state.hover ? (
                <div onClick={() => this.setState({ addAgent: false, editAgent: false, hide_filter: true })} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ marginRight: "10px" }}>Reduire</div> <RemoveIcon />
                </div>
              ) : (
                <RemoveIcon onClick={() => this.setState({ addAgent: false, editAgent: false, hide_filter: true })} />
              ))}
          </div>
        </div>
        {this.state.hide_filter && (
          <section className="admin_ordersPage_actions">
            <section className="actions_filter">
              <article initialValues={initialValues_filter} onSubmit={this.onSubmit_filter} validator={() => ({})}>
                <section>
                  <Select
                    className="reactSelect"
                    options={countrySelect.map((country) => {
                      return { label: country.name, value: country.id };
                    })}
                    placeholder="Country Agent"
                    isClearable
                    onChange={this.handleSelectAgent_filter}
                  />
                </section>
                <section>
                  <Select
                    className="reactSelect"
                    options={isActiveSelect.map((isActiv) => {
                      return { label: isActiv.name, value: isActiv.id };
                    })}
                    placeholder="Status Agent"
                    isClearable
                    onChange={this.handleSelectActive_filter}
                  />
                </section>
              </article>
              <article>
                <button className="button_search" type="submit" onClick={this.onSubmit_filter}>
                  Search
                </button>
                <button className="refreshButton" onClick={() => window.location.reload(false)}>
                  {RefreshSvg}
                </button>
              </article>
            </section>
          </section>
        )}

        {this.state.addAgent && (
          <div className="admin_addAgent_addForm">
            <Row className="perfectC">
              <h4>{Translate("agents", "create")}</h4>
            </Row>
            <Row className="perfectC">
              <Col xs={12} sm={6}>
                <Form className="w-100" onSubmit={this.handleSubmit}>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("agents", "full_name")}</Form.Label>
                    <Form.Control type="text" size="lg" required name="full_name" placeholder="Please enter the agent's name" value={this.state.agent.full_name} onChange={this.handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("agents", "username")}</Form.Label>
                    <Form.Control type="text" size="lg" required name="username" placeholder="Please enter the agent's username" value={this.state.agent.username} onChange={this.handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("auths", "password")}</Form.Label>
                    <Form.Control type="password" size="lg" required name="password" placeholder="Please enter the agent's password" value={this.state.agent.password} onChange={this.handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("auths", "regionsagent")}</Form.Label>
                    <select className="form-control form-control-lg" defaultValue={this.state.country} onChange={this.onChangeCountry}>
                      <option value={1}>{Translate("agents", "dzagent")}</option>
                      <option value={2}>{Translate("agents", "tnagent")}</option>
                    </select>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("agents", "gender")}</Form.Label>
                    <Form.Check type={"radio"} size="lg" required name="sex" label={`Male`} value={"M"} checked={this.state.agent.sex === "M"} onChange={this.handleChange} />
                    <Form.Check type={"radio"} size="lg" required name="sex" label={`Female`} value={"F"} checked={this.state.agent.sex === "F"} onChange={this.handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("agents", "orderslimit")}</Form.Label>
                    <Form.Control type="number" name="orders_limit" min={30} max={500} value={this.state.agent.orders_limit} onChange={this.handleChange} />
                  </Form.Group>
                  <Form.Group as={Row} className="perfectC">
                    <Button type="submit" size="lg" className="btn btn-primary">
                      {Translate("agents", "create")}
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        )}
        {this.state.editAgent && (
          <div className="admin_addAgent_addForm">
            <Row className="perfectC">
              <h4>{Translate("agents", "create")}</h4>
            </Row>
            <Row className="perfectC">
              <Col xs={12} sm={6}>
                <Form className="w-100" onSubmit={this.handleSubmitModif}>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("agents", "full_name")}</Form.Label>
                    <Form.Control type="text" size="lg" required name="full_name" placeholder="Please enter the agent's name" value={this.state?.idAgent.full_name} onChange={this.handleChangeModif} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("agents", "username")}</Form.Label>
                    <Form.Control type="text" size="lg" required name="username" placeholder="Please enter the agent's username" value={this.state?.idAgent.username} onChange={this.handleChangeModif} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("auths", "regionsagent")}</Form.Label>
                    <select className="form-control form-control-lg" name="country" value={this.state?.idAgent.country} onChange={this.handleChangeModif}>
                      <option value={1}>{Translate("agents", "dzagent")}</option>
                      <option value={2}>{Translate("agents", "tnagent")}</option>
                    </select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("auths", "Status de l'agent")}</Form.Label>
                    <select className="form-control form-control-lg" name="is_active" value={this.state?.idAgent.is_active} onChange={this.handleChangeModif}>
                      <option value={"True"}>{Translate("agents", "Active")}</option>
                      <option value={"False"}>{Translate("agents", "Inactive")}</option>
                    </select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("agents", "gender")}</Form.Label>
                    <Form.Check type={"radio"} size="lg" required name="sex" label={`Male`} value={"M"} checked={this.state?.idAgent.sex === "M"} onChange={this.handleChangeModif} />
                    <Form.Check type={"radio"} size="lg" required name="sex" label={`Female`} value={"F"} checked={this.state?.idAgent.sex === "F"} onChange={this.handleChangeModif} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex flxDrc">{Translate("agents", "orderslimit")}</Form.Label>
                    <Form.Control type="number" name="orders_limit" min={30} max={500} value={this.state?.idAgent.orders_limit} onChange={this.handleChangeModif} />
                  </Form.Group>
                  <Form.Group as={Row} className="perfectC">
                    <Button type="submit" size="lg" className="btn btn-primary">
                      {Translate("agents", "Modifier")}
                    </Button>
                  </Form.Group>
                  {this.props.PutAgents === "2" && <p style={{ color: "red" }}>réessayez s'il vous plaît</p>}
                </Form>
              </Col>
            </Row>
          </div>
        )}

        <div className="admin_addAgent_agentListTable">
          <div className="admin_addAgent_agentListTable_top">
            <h2>{Translate("titles", "agentslist")}</h2>
            <input type="search" onChange={this?.handleFilterAgentList} placeholder="Rechercher..." />
          </div>
          {this.state.is_loading_table && <TableLoader />}
          <table>
            <thead>
              <tr>
                <th>{Translate("agents", "full_name")}</th>
                <th>{Translate("auths", "username")}</th>
                <th>{Translate("agents", "rateconf")}</th>
                <th>{Translate("agents", "orderslimit")}</th>
                <th>Statistics</th>
                <th>Supervisor</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {this.state?.agentList.filter(this.filterAgents).map((agent, index) => {
                const toBeConfirmed = agent?.stats?.orders_2b_confirmed;
                const callBack = agent?.stats?.orders_callback;
                const cancelledToday = agent?.stats?.orders_cancelled_today;
                const confirmedToday = agent?.stats?.orders_confirmed_today;
                const pending = agent?.stats?.orders_pending;
                const postponedToday = agent?.stats?.orders_postponed_to_today;
                const TotalOrdersToBeConfirmed = agent?.stats?.orders_2b_confirmed + agent?.stats?.orders_callback + agent?.stats?.orders_cancelled_today + agent?.stats?.orders_confirmed_today + agent?.stats?.orders_pending + agent?.stats?.orders_postponed_to_today;
                const Pourcentage = (confirmedToday * 100) / TotalOrdersToBeConfirmed;
                const rate = Math.round(Pourcentage * 100) / 100;
                const daughnut_datasets = [
                  {
                    label: "Orders",
                    backgroundColor: [
                      "#fac710", //pending
                      "#1bb99a", // confirmed today
                      "#007bff", // callback
                      "#dc3545", // canceled today
                      "#a436ac", // postponed today
                    ],
                    data: [pending, confirmedToday, callBack, cancelledToday, postponedToday],
                  },
                ];
                const daughnut_labels = [
                  // "To be confirmed",
                  "Pending",
                  "Confirmed today",
                  "Callback",
                  "Cancelled today",
                  "Postponed to today",
                ];

                const daughnut_options = {
                  animation: {
                    animateScale: true,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                };
                const daughnut_data = {
                  labels: daughnut_labels,
                  datasets: daughnut_datasets,
                };

                return (
                  <tr key={index}>
                    <td>{agent?.full_name}</td>
                    <td>{agent?.username}</td>
                    <td>{isNaN(rate) ? `${0}%` : `${rate}%`}</td>
                    <td>{agent?.orders_limit}</td>
                    <td className="agentListaTable_statsColumn">
                      <figure>
                        <Doughnut data={daughnut_data} height={"100"} width={"100"} options={daughnut_options} />

                        <figcaption>
                          To be confirmed : <mark>{toBeConfirmed}</mark>
                        </figcaption>
                      </figure>
                    </td>
                    <td>{agent?.supervisor?.username}</td>
                    <td>
                      <div style={{ padding: "5.5px", background: "var(--bdgclInp)", cursor: "pointer", margin: "0 5px", borderRadius: "6px", display: "inline-flex", float: "right" }}>
                        <EditIcon onClick={() => this.setState({ editAgent: true, idAgent: agent, hide_filter: false })} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { GettingAgents, AgentsList, PutAgents, AgentsModified } = state.common;
  const { registerFlag, registerData } = state.user;

  return { GettingAgents, AgentsList, registerFlag, registerData, PutAgents, AgentsModified };
}

const actionCreators = {
  ListAgents: commonActions.ListAgents,
  RegisterAgent: userActions.Register,
  SendAlert: alertActions.SendAlert,
  ModifierAgents: commonActions.ModifierAgents,
};

const connectedAddAgentPage = connect(mapState, actionCreators)(AddAgentPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedAddAgentPage);
export { QueryProps as AddAgentPage };
