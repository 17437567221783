import React, { useEffect, useState } from "react";

import "./dropdown.css";

export default function DropDown(props) {
  const { options = [] } = props;

  const productsList = options.map((data) => {
    return {
      value: data.id,
      product_name: data.logistical_description,
      quantity: data.quantity,
    };
  });

  const listItems = productsList.map((option, index) => {
    return (
      <option key={option.value} value={option.value}>
        {option.product_name} {">>>"} En stock : {option.quantity}
      </option>
    );
  });
  console.log();
  return (
    <select name={props.name} className="input-password-mobile-small input-password-mobile input" value={props.productOrder ?? ""} onChange={props.onChange}>
      {listItems}
    </select>
  );
}
