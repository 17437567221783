export const orderConstants = {
  UPLOAD_ORDERS_REQUEST: "UPLOAD_ORDERS_REQUEST",
  UPLOAD_ORDERS_SUCCESS: "UPLOAD_ORDERS_SUCCESS",
  UPLOAD_ORDERS_FAILURE: "UPLOAD_ORDERS_FAILURE",

  EXPORT_ORDERS_REQUEST: "EXPORT_ORDERS_REQUEST",
  EXPORT_ORDERS_SUCCESS: "EXPORT_ORDERS_SUCCESS",
  EXPORT_ORDERS_FAILURE: "EXPORT_ORDERS_FAILURE",

  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDERS_FAILURE: "GET_ORDERS_FAILURE",
  GET_ORDERS_REQUEST: "GET_ORDERS_REQUEST",

  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",
  GET_PRODUCTS_REQUEST: "GET_PRODUCTS_REQUEST",
};
