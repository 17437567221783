import axios from "../../http-common";
import Cookies from "js-cookie";

const getAgentInfo = () => {
  return axios.get(`confirmation/confirmation_agents/me/`, {
    headers: { Authorization: `Token ${Cookies.get("auth")}` },
  });
};

const Services = {
  getAgentInfo,
};
export default Services;
