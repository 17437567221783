import axios from "axios";
import Cookies from "js-cookie";

export const API_URL = process.env.REACT_APP_API_URL;

window.axios = axios;

export const RequestService = {
  GetRequest,
  PostRequest,
  PatchRequest,
  DeleteRequest,
  PutRequest,
};
export function AuthHeader() {
  let token = Cookies.get("auth");
  return { Authorization: "Token " + token };
}

function GetRequest(url, headers, prefix = undefined) {
  // return axios.get(`${url}`, {
  return axios.get(`${prefix || API_URL}${url}`, {
    // httpsAgent: agent,
    headers: { "Content-Type": "application/json", ...headers },
  });
}

function PostRequest(url, data, headers, prefix = undefined) {
  return axios.post(`${prefix || API_URL}${url}`, data, {
    headers: { "Content-Type": "application/json", ...headers },
  });
}
function PatchRequest(url, data, headers, prefix = undefined) {
  return axios.patch(`${prefix || API_URL}${url}`, data, {
    headers: { "Content-Type": "application/json", ...headers },
  });
}
function DeleteRequest(url, headers, prefix = undefined) {
  return axios.delete(`${prefix || API_URL}${url}`, {
    headers: { "Content-Type": "application/json", ...headers },
  });
}
function PutRequest(url, data, headers, prefix = undefined) {
  return axios.put(`${prefix || API_URL}${url}`, data, {
    headers: { "Content-Type": "application/json", ...headers },
  });
}
