import globalServices from "../../../services/global/global.services";

const getAllAgents = async (params) => {
  let response = [];
  let error = [];

  try {
    response = await globalServices.getAllAgents();
    if (response) {
      params?.setAgentList(response?.data);
    }
  } catch (err) {
    error = err;
    if(params?.setAgentListError && error?.response){
        params?.setAgentListError(error?.response)
    }
  }
};


const getAllStores = async (params) => {
  let response = [];
  let error = [];

  try {
    response = await globalServices.getAllStores();
    if (response) {
      params?.setStoresList(response?.data);
    }
  } catch (err) {
    error = err;
    if(params?.setStoresListError && error?.response){
        params?.setStoresListError(error?.response)
    }
  }
};

const globalAdminController={
    getAllAgents,
    getAllStores
}

export default globalAdminController;