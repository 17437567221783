import React from 'react';
import {Button} from "react-bootstrap"
export default function CustomButton(props){
    return(
        <div className="RlPs FlWd">
            {props.startIcon && <span className="mx-2">{props.startIcon}</span>}
            <button className={"text-white rmOut FntfMin StBrdRd BdgTrans DlBrd StSizLn Fntcl stbold CrsPoi p-2 "+props.className} onClick={props.onClick} >
                {props.text}
            {props.icon && <span className="mx-2">{props.icon}</span>}
            </button>
        </div>
        // <Button className={"rmOut FntfMin StBrdRd BdgTrans DlBrd StSizLn StWht stbold CrsPoi "+props.className}>
        //     {props.text}

        // </Button>
    ) 
}