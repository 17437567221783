import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../redux/actions";
import { NavBar } from "../../components/custom/NavBar";
import { Route, Switch, Redirect } from "react-router-dom";
import { AlertPage } from "../AlertPage/";
import { Translate } from "../../utils/lang/translate";
import { DashboardPage } from "./DashboardPage/DashboardPage";
// import { CallSessionPage } from './CallSessionPage/CallSessionPage';
import { ListenerFile } from "../../components/common/ListenerEvent";
import ImportOrderPage from "../importorders/Import";
import { AgentPerfPage } from "../statistics/AgentsPerformancePage";
import OrdersPage_refactored from "../admin/orders/Orders_refactored";
// import CallSession_refactored from '../CallSessionPage/CallSession-refactored';
import { CallSessionRefactored } from "../../pages/dashboard/CallSessionPage/CallSession-refactored";
import { AddAgentPage } from "../admin/agents/AddAgent";
import { PreviousOrdersPage } from "../orders/OrdersPage";

class IndexStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewFeaturePopup: false,
      showNewFeatAnnoun: true,
    };
    if (this.props.FetchingUser != "0" || this.props.FetchingUser != "2") {
      this.props.GetProfileUser();
    }
  }

  render() {
    const { FetchingUser } = this.props;
    if (FetchingUser === "2") {
      this.props.SendAlert("error", Translate("alert", "failedfetchprofile"));
    }

    const dso__fjnk67128ç = localStorage.getItem("_msdrvx459") === "dsq@s(-è_)9853!s*d&34";
    return (
      <div className="StAbs FullCont FlWd FlHg BdgGc">
        <div className="StAbs FullCont">
          <div className="FlHg StWdsc HidScrolStil">
            <NavBar {...this.props} />
            {true && (
              <div className="FullCont">
                <Switch>
                  <Route path="/dashboard" component={DashboardPage} />
                  {/* {!dso__fjnk67128ç  && <Route path="/callsession" component={CallSessionPage} />} */}
                  {!dso__fjnk67128ç && <Route path="/callsession" component={CallSessionRefactored} />}
                  {!dso__fjnk67128ç && <Route path="/previousorders" component={PreviousOrdersPage} />}
                  {dso__fjnk67128ç && <Route path="/performance" component={AgentPerfPage} />}
                  {dso__fjnk67128ç && <Route path="/manage/agents" component={AddAgentPage} />}
                  {dso__fjnk67128ç && <Route path="/manage/orders" component={OrdersPage_refactored} />}
                  {dso__fjnk67128ç && <Route path="/import" component={ImportOrderPage} />}

                  <Redirect from="*" to="/dashboard" />
                </Switch>
              </div>
            )}
            <AlertPage />
            <ListenerFile />
          </div>
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { FetchingUser, authData, dataProfile } = state.user;
  return { FetchingUser, authData, dataProfile };
}

const actionCreators = {
  GetProfileUser: userActions.GetProfileUser,
  SendAlert: alertActions.SendAlert,
};

const connectedIndexStore = connect(mapState, actionCreators)(IndexStore);
export default connectedIndexStore;
