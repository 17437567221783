import axios from "../../http-common";
import Cookies from "js-cookie";

export const getAllAgents = (filter) => {
  return axios.get(filter ? "confirmation/confirmation_agents/" + filter : "confirmation/confirmation_agents/", {
    headers: { Authorization: `token ${Cookies.get("auth")}` },
  });
};

export const getAllStores = () => {
  return axios.get("confirmation/stores/", {
    headers: { Authorization: `token ${Cookies.get("auth")}` },
  });
};

export const getAllWilayas = () => {
  return axios.get("shared/wilayas/", {
    headers: { Authorization: `token ${Cookies.get("auth")}` },
  });
};

export const getAllCommunes = () => {
  return axios.get("shared/communes/", {
    headers: { Authorization: `token ${Cookies.get("auth")}` },
  });
};

export const getCancellationReasons = () => {
  return axios.get("shared/abort_reasons/", {
    headers: { Authorization: `Token ${Cookies.get("auth")}` },
  });
};
const Services = {
  getAllAgents,
  getAllStores,
  getAllWilayas,
  getAllCommunes,
  getCancellationReasons,
};
export default Services;
