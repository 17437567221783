import React from "react";
import { Form } from "react-bootstrap";
export default function GroupeForm(props) {
  return (
    // <div className="grp-fm StdirLn StFnt" style={props.style}>
    //     <div className="SsGrpRm RlPs">
    //         <label htmlFor={props.id}>{props.text}</label>
    //         <div className="StAbs StLanNl StTpNl StOpcVal">
    //             {props.more}
    //         </div>
    //     </div>
    //     <div className="SsGrpRm StMrg RlPs">
    //         {props.textarea ?<textarea className={"FntfMin lytwdp2p StdirLn FrInp StSizLn StHgInp StBrdRd Fntcl "+(props.stclass?props.stclass :'')} style={{resize:"none",height:"157px"}} onChange={props.workfun} name={props.name} placeholder={props.placeholder} id={props.id} value={props.value}></textarea> :
    //         <input className={"FntfMin lytwdp2p StdirLn FrInp StSizLn StHgInp StBrdRd Fntcl "+(props.stclass?props.stclass :'')} type={props.type} name={props.name} id={props.id} defaultValue={props.defaultValue} value={props.value} max={props.max} min={props.min} placeholder={props.placeholder} pattern={props.pattern}  onChange={props.workfun} autoComplete="off"/>}
    //         <span className="StRedClr">{props.error}</span>
    //     </div>
    // </div>
    <Form.Group className="col-xs-12">
      <Form.Label className="d-flex flxDrc" >{props.text}</Form.Label>
      <Form.Control
        className={
          "FntfMin lytwdp2p StdirLn FrInp StSizLn StHgInp StBrdRd Fntcl " +
          (props.stclass ? props.stclass : "")
        }
        type={props.type}
        as={props.textarea?"textarea":"input"}
        rows={props.rows}
        name={props.name}
        id={props.id}
        defaultValue={props.defaultValue}
        value={props.value}
        max={props.max}
        min={props.min}
        placeholder={props.placeholder}
        pattern={props.pattern}
        onChange={props.workfun}
        autoComplete="off"
      />
      <Form.Text className="text-danger">{props.error}</Form.Text>
    </Form.Group>
  );
}
