import { connect } from "react-redux";
import { Router } from 'react-router-dom';
import { configureUrlQuery } from 'react-url-query';
import {history} from './utils/history'
import IndexAuth from './pages/auth/'
import IndexApp from './pages/dashboard/'
import PrivateRoute from './privateRoute';
import Helmet from 'react-helmet';
import Cookies from 'js-cookie';
import {ArDesign,EnDesign} from './ConstDesign'

configureUrlQuery({ history });
const lang=Cookies.get('lang');

const App = () => {
  let style=lang==="ar"?ArDesign: EnDesign

  return (
    <Router history={history}>
      <Helmet>{style}</Helmet>
        <PrivateRoute
          private={IndexApp}
          public={IndexAuth}
        />
    </Router>
  );
}
export default connect()(App);
