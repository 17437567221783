import React, { Component } from "react";
import { connect } from "react-redux";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import { callActions } from "../../redux/actions/";
import { PersonSvg, MicroOnSvg, PauseSvg, HangUpSvg, CallSvg } from "../common/IconSvg";

import { Container, Col, Row, Spinner } from "react-bootstrap";

const urlPropsQueryConfig = {
  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
};

class CallBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hangedUp: true,
      paused: false,
      muted: false,
    };
  }

  componentDidMount() {}
  componentWillReceiveProps(nextProps) {
    const { StartingCall } = nextProps;

    if (this.props.StartingCall === "0" && StartingCall === "1") this.setState({ hangedUp: false });
  }

  Dial() {
    this.props.Dial();

    document.querySelector("#dial-phone-number").click();
  }
  toggleMuteMic() {
    const { muted } = this.state;
    this.setState({ muted: !muted });
  }
  PauseResumeCall() {
    const { paused } = this.state;
    this.setState({ paused: !paused });
  }

  hangUpCall() {
    this.setState({
      hangedUp: true,
    });
    this.props.HangUp();
  }

  render() {
    const { StartingCall } = this.props;

    return (
      <Container fluid className="ClSidTh">
        <Row className="flxDrc">
          <Col xs="12" sm="8">
            <div className="InFlx flxDrc p-2 justify-content-center justify-content-md-start">
              <div style={{ width: "8vh", height: "8vh", borderRadius: "50%" }} className="perfectC BdgWhite BlackSvg">
                {PersonSvg}
              </div>

              <div className="px-3">
                <div className="InFlx AlgnItm flxDrc">
                  <h6 className="stCapit mb-1">{this.props.customer.customer_name}</h6>
                </div>
                <p className="StOpcVal" style={{ marginBottom: "0px" }}>
                  <a href={`tel:${this.props.customer.customer_phone}`} id="dial-phone-number">
                    {this.props.customer.customer_phone}
                  </a>
                </p>
                <p className="StOpcVal">
                  <a href={`tel:${this.props.customer.customer_phone_2}`} id="dial-phone-number">
                    {this.props.customer.customer_phone_2}
                  </a>
                </p>
              </div>
            </div>
          </Col>
          <Col xs="12" sm="4">
            <div className="FlHg InFlx AlgnItm flxDrc StFlxStr p-2 justify-content-around">
              {!this.state.hangedUp && (
                <React.Fragment>
                  <h4>{"00:12"}</h4>
                  {false && (
                    <div
                      style={{
                        width: "8vh",
                        height: "8vh",
                        borderRadius: "50%",
                      }}
                      className={"CrsPoi p-2 call_act_btn perfectC " + (this.state.muted ? "TxDng" : "BdgLight")}
                      onClick={() => this.toggleMuteMic()}
                    >
                      {MicroOnSvg}
                    </div>
                  )}
                  {false && (
                    <div
                      style={{
                        width: "8vh",
                        height: "8vh",
                        borderRadius: "50%",
                      }}
                      className={"CrsPoi p-2 call_act_btn perfectC " + (this.state.paused ? "bg-warning" : "BdgLight")}
                      onClick={() => this.PauseResumeCall()}
                    >
                      {this.state.paused ? CallSvg : PauseSvg}
                    </div>
                  )}
                  <div style={{ width: "8vh", height: "8vh", borderRadius: "50%" }} className="CrsPoi p-2 call_act_btn perfectC TxDng" onClick={() => this.hangUpCall()}>
                    {HangUpSvg}
                  </div>
                </React.Fragment>
              )}
              {this.state.hangedUp && StartingCall === "0" && (
                <React.Fragment>
                  <div className="p-2  perfectC ">
                    <h6 className="StOpcVal mx-1">{"Calling"} </h6>
                    <Spinner size="sm" animation="grow" variant="light" className="mx-2" />
                    <Spinner size="sm" animation="grow" variant="light" className="mx-2" />
                    <Spinner size="sm" animation="grow" variant="light" className="mx-2" />
                  </div>
                </React.Fragment>
              )}
              {this.state.hangedUp && (
                <React.Fragment>
                  <div style={{ width: "8vh", height: "8vh", borderRadius: "50%" }} className="CrsPoi p-2 call_act_btn perfectC BgrdPrim" onClick={() => this.Dial()}>
                    {CallSvg}
                  </div>
                </React.Fragment>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
function mapState(state) {
  const { StartingCall, CallData, HangingUp } = state.call;
  return { StartingCall, CallData, HangingUp };
}

const actionCreators = {
  Dial: callActions.Dial,
  HangUp: callActions.HangUp,
};

const connectedCallBar = connect(mapState, actionCreators)(CallBar);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedCallBar);
export { QueryProps as CallBar };
