import { alertConstants } from '../constants';

export function alert(state,action) {
  switch (action.type) {
    case alertConstants.SEND_ALERT_SUCCESS:
      return {
        alertCode:"success",
        alertData: action.payload
      };
    case alertConstants.SEND_ALERT_FAILURE:
      return {
        alertCode:"error",
        alertData: action.payload
      };
    case alertConstants.SEND_ALERT_REQUEST:
      return {
        alertCode:"31",
        alertData: action.payload
      };
    case 'Remove':
      return {
        
      }
    default:
        return {...state}
    }
}