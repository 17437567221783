import React from 'react';
export default function HeadLog(props){
    return(
        <div className="">
            <div className="InFlx JstfCnt">
                <img src="/assets/img/maystro-blue.png" alt="Maystro-Delivery"/>
            </div>
            <div >
                <div className="StMar InFlx JstfCnt flxDrc">
                    <h2 className="DlMg">{props.titleHead}</h2>
                </div>
                <div className="StMar d-flex flxDrc">
                    <p className="DlMg">{props.descHead}</p>
                </div>
            </div>
        </div>
    )
}