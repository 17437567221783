import ReactGA from "react-ga"
var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
export const initGAPg = () => {           
    // ReactGA.initialize(process.env.REACT_APP_STATE!=="prod"? "UA-175188708-2" : "UA-175188708-3"); 
    // ReactGA.pageview(window.location.pathname +  
    //     window.location.search); 
}
export const Event = (category, action, label) => {
    // ReactGA.event({
    //   category: category,
    //   action: action,
    //   label: label
    // });
  };
  