import Cookies from 'js-cookie'

export default function Dictionary() {
    const lang=Cookies.get("lang")
    switch(lang){
    case "fr":
        return [require('./constants/fr'),'fr-MA','fr-FR']
    case "ar":
        return [require('./constants/ar'),'en-UK','ar-DZ']
    default:
        Cookies.set("lang","en")
        return [require('./constants/en'),'fr-MA','en-GB']
    }
}