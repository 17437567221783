import React,{Component} from 'react'
import {LoginPage} from './LoginPage'
import {
    Route,
    Switch,
    Redirect
  } from 'react-router-dom';
import Helmet from 'react-helmet';
import { LoginPageAdmin } from './LoginPageAdmin';
import { AuthRootStyle } from '../../components/styles/styles-constants';
export default class IndexAuth extends Component{
    render(){
        return(
            <div className="FlHg">
                <Helmet>
                <style>{AuthRootStyle}</style>
                </Helmet>
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/admin/login" component={LoginPageAdmin} />
                    <Redirect from="*" to="/login"/>
                </Switch>
            </div>
        )
    }
}