import axios from "../../http-common";
import Cookies from 'js-cookie'

const importOrders = (formData) => {
  return axios.post(
    `confirmation/bulk_upload_order_2b_confirmed/`,formData,
    {
      headers: { Authorization: `token ${Cookies.get('auth')}` },
    }
  );
};

const Services = {
    importOrders,
};
export default Services;
