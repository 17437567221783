import { statConstants } from '../constants';
export function statistics(state , action) {
    switch (action.type) {
        
        case statConstants.GET_AGENTS_PERF_REQUEST:
            return {
                ...state,
                GettingAgentsPerf:"0",
                perfData: action.payload
            };
        case statConstants.GET_AGENTS_PERF_SUCCESS:
            return {
                ...state,
                GettingAgentsPerf:"1",
                perfData: action.payload
            };
        
        case statConstants.GET_AGENTS_PERF_FAILURE:
            return {
                ...state,
                GettingAgentsPerf:"2",
                perfData: action.payload
            };
        case statConstants.GET_TODAY_STATS_REQUEST:
            return {
                ...state,
                GettingTodayStats:"0",
                StatData: action.payload
            };
        case statConstants.GET_TODAY_STATS_SUCCESS:
            return {
                ...state,
                GettingTodayStats:"1",
                StatData: action.payload
            };
        
        case statConstants.GET_TODAY_STATS_FAILURE:
            return {
                ...state,
                GettingTodayStats:"2",
                StatData: action.payload
            };
        
        case "Clear_Dashboard":
            return {}
        default:
            return {...state}
        }
}