export const sessionConstants = {
    GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
    GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
    GET_ORDER_FAILURE: 'GET_ORDER_FAILURE',
    CACHE_ORDER: 'CACHE_ORDER',
    GO_TO_NEXT_ORDER: 'GO_TO_NEXT_ORDER',
    
    UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
    UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',


    START_SESSION_REQUEST: 'START_SESSION_REQUEST',
    START_SESSION_SUCCESS: 'START_SESSION_SUCCESS',
    START_SESSION_FAILURE: 'START_SESSION_FAILURE',

    END_SESSION_REQUEST: 'END_SESSION_REQUEST',
    END_SESSION_SUCCESS: 'END_SESSION_SUCCESS',
    END_SESSION_FAILURE: 'END_SESSION_FAILURE',

    PAUSE_CALL_REQUEST: 'PAUSE_CALL_REQUEST',
    PAUSE_CALL_SUCCESS: 'PAUSE_CALL_SUCCESS',
    PAUSE_CALL_FAILURE: 'PAUSE_CALL_FAILURE',
    
}