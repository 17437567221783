import React, { useState } from "react";
import "./AssignOrders.scss";
import CloseIcon from "@mui/icons-material/Close";

const AssignOrders = (props) => {
  const { closeModal, component_props, postponed_to, onChange } = props;
  const { agentList, assignOrdersBackendError, assignOrdersError } = component_props;
  const handleCloseAssignOrdersModal = () => {
    closeModal.setIsOpenModal(false);
  };
  const [statusLabel, setStatusLabel] = useState();

  return (
    <article className="assignOrders">
      <section className="assignOrderModal_content">
        <section className="top">
          <h3>Assign orders to agent</h3>
          <span onClick={handleCloseAssignOrdersModal}>
            <CloseIcon />
          </span>
        </section>
        <section className="bottom">
          <section className="agentDropDownList">
            <input id="agentList_Input" list="agentList" name="agentList_input" autoComplete="off" placeholder="Agent" type="search" />
            <datalist id="agentList">
              {agentList &&
                agentList.map((agent, index) => {
                  return <option key={index} data-value={agent?.id} value={agent?.full_name}></option>;
                })}
            </datalist>
            <input
              id="statusList_Input"
              list="statusList"
              name="statusList_input"
              autoComplete="off"
              placeholder="Status"
              type="search"
              value={statusLabel}
              onChange={(e) => {
                setStatusLabel(e.target.value);
              }}
            />
            <datalist id="statusList">
              <option data-value={10} value={"Pending"}></option>
              <option data-value={100} value={"Cancelled"}></option>
              <option data-value={30} value={"Postponed"}></option>
            </datalist>
            {statusLabel === "Postponed" && <input id="postponed_to" name="postponed_to" type="date" value={postponed_to} onChange={onChange} />}
          </section>
          <button type="submit">Submit</button>
          <div className="errorMessage">
            {assignOrdersBackendError}
            <br></br>
            {assignOrdersError}
          </div>
        </section>
      </section>
    </article>
  );
};

export default AssignOrders;
