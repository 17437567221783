import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Sorter } from '../../utils/Sorter';

import {alertActions,sessionActions} from "../../redux/actions/"

import {
  Translate,
} from "../../utils/lang/translate";
import ContainerPage from "../../components/common/ContainerPage";
import {Container,Col, Row, Form, Button} from "react-bootstrap"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from "../../components/common/Loader";
import { EmptyScreen } from "../../components/common/EmptyScreen";
import Cookies from "js-cookie";
import { CheckSvg, CloseSvg } from "../../components/common/IconSvg";

const { SearchBar } = Search;

const mapResponseOrders=(orders)=>{

  return orders.map(order=>({
    ...order,
    store_name:order.store ? order.store.name: "",
    agent_name:order.confirmation_agent ? order.confirmation_agent.full_name: ""
    
  }))
}


const TABLE_COLUMNS=[
  {dataField: 'external_id', comparator: Sorter.number_sorter,text: 'ID'},
  {dataField: 'status', text: Translate("orders","status")},
  {dataField: 'customer_name', comparator: Sorter.number_sorter,text: Translate("orders","customername")},
  {dataField: 'customer_phone', comparator: Sorter.number_sorter,text: Translate("orders","customerphonenumber")},
  {dataField: 'product_name', comparator: Sorter.number_sorter,text: Translate("orders","productlist")},
  {dataField: 'store_name', comparator: Sorter.number_sorter,text: Translate("orders","storename")},  
  
]


class PreviousOrdersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      rows: [],
      orders:localStorage.getItem("cached")?JSON.parse(localStorage.getItem("cached")) : [],
     
      errors: {},
    };
    
    
  }
  
  
  componentDidMount() {
    document.title = "Maystro Confirmation - " + Translate("titles", "order");
    let cachedrows=localStorage.getItem("cached")?JSON.parse(localStorage.getItem("cached")) : [];
    let rows=cachedrows.map(row=>({
      ...row,
      action_btns:( <div className="d-flex justify-content-between">
        <Button onClick={()=>this.UpdateOrder(row,"confirm")} className="m-2 btn btn-success btn-circle">{CheckSvg}</Button>
        <Button onClick={()=>this.UpdateOrder(row,"cancel")} className="m-2 btn btn-danger btn-circle">{CloseSvg}</Button>
      </div>)
    }))
    this.setState({
      columns: [
        ...TABLE_COLUMNS,
        { 
        text:Translate("titles","actions"),
        dataField: "action_btns"
        }],
      rows,
      orders:cachedrows,
    })
  }
  componentWillReceiveProps(nextProps) {
    const {GettingOrders,OrdersList,page}=nextProps
    
    const {UpdattingOrder}=nextProps
    
    if(this.props.UpdattingOrder==="0" && UpdattingOrder==="2"){
      this.props.SendAlert("error", Translate("alert", "failedupdateorder"));
    }
    if(this.props.UpdattingOrder==="0" && UpdattingOrder==="1"){
      this.props.SendAlert("success", Translate("alert", "successdupdateorder"));      
      
    }
    
    if(this.resFilter || this.state.ForceProps || this.props.page!==page){          
      this.resFilter=false
      this.setState({
        ForceProps:false
      })
      this.exctuteFilterUrl(this.getFilterToState(nextProps))
    }

    if(this.props.GettingOrders==="0" && GettingOrders==="1" && OrdersList && OrdersList.ressuc ){
      // document.getElementById("Container-root").scrollTo(0,0)
      this.setState({
          orders:mapResponseOrders(OrdersList.ressuc.results),
          nextpage:OrdersList.ressuc.next,
          prevpage:OrdersList.ressuc.previous,
          count:OrdersList.ressuc.count,
          wilaysData:this.state.wilaysData
      })
    }

    
    
  }
  UpdateOrder(order,action){
    let data={
      ...order,
     
      wilaya: order.city?order.city[0]:null,
      commune: order.district?order.district.id:null,
      customer_adress: order.destination_text,
      cancellation_reason: order.cancellation_reason ?order.cancellation_reason.abortReason: null ,
    }
 
    
    this.props.UpdateOrder(data,action);

  }
 


  render() {

    return (
      <ContainerPage
        page_title={Translate("titles","order")}
        subTitle={""}
      >
         
        <ToolkitProvider
          keyField="id"
          data={ this.state.rows }
          columns={ this.state.columns }
          search
        >
           {
                  props => (
        <Container fluid className="px-5 mt-5">

            <Row className="my-5 justify-content-center align-items-end">
                <Col xs={12} sm={8} className="py-5 py-md-0">
                    <Row>
                        
                        <Col xs={12} sm={10}>
                            <Form.Label>Search</Form.Label>
                            <div>
                              <SearchBar { ...props.searchProps } 
                              placeholder="Search anything here ..."
                              className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                              />

                            </div>
                        </Col>
                    </Row>
                </Col>


                

            </Row>

            {this.state.rows.length===0 && <EmptyScreen/>}

            {Array.isArray(this.state.rows) && this.state.rows.length>0 && 
            <Row className="px-2 py-2 justify-content-center">
               
                      <BootstrapTable 
                        classes="table table-dark table-hover table-responsive"
                        keyField='id' 
                        data={ this.state.rows } 
                        columns={ this.state.columns } 
                        { ...props.baseProps }
                      />
                      
                 
              
                    
                
            </Row>}

            
        </Container>   
         )
        } 
        </ToolkitProvider>

      </ContainerPage>
    );
  }
}
function mapState(state) { 
  const {UpdattingOrder,UpdateData}=state.session

  return {UpdattingOrder,UpdateData};
   
}

const actionCreators = {
  UpdateOrder:sessionActions.UpdateOrder,
  SendAlert: alertActions.SendAlert,
};

const connectedPreviousOrdersPage = connect(mapState, actionCreators)(PreviousOrdersPage);
export { connectedPreviousOrdersPage as PreviousOrdersPage };
