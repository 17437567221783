import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RequestService } from "src/services/api/api";

const WILAYAS_API_ENDPOINT = 'shared/wilayas/';

export interface Wilaya {
    id: number;
    name: string;
}

export interface WilayaState {
    wilayas: Wilaya[];
    country: string;
    loading: boolean;
}

const initialState: WilayaState = {
    wilayas: [],
    country: '',
    loading: false
};

export const fetchWilayas = createAsyncThunk('wilayas/fetch', async (country_id: number): Promise<Wilaya[]> => {
    try {
        const res = await RequestService.getRequest<Array<[number, string]>>(WILAYAS_API_ENDPOINT, { params: { country_id } });
        return res.map(([id, name]: [number, string]) => ({ id, name }));
    } catch {
        return [];
    }
});

const wilayaSlice = createSlice({
    name: 'wilaya',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchWilayas.pending, (state, _) => {
            state.loading = true;
        }).addCase(fetchWilayas.fulfilled, (state, action) => {
            state.wilayas = action.payload;
            state.loading = false;
        });
    },
});

export default wilayaSlice.reducer;