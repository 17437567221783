import { RequestService } from "src/services/api/api";
import { Order, OrderStatus } from "../common";

const CALLSESSION_NEXT_ORDER_API_ENDPOINT = 'confirmation/agents/orders_2b_confirmed/next/';
const CALLSESSION_LIST_ORDERS_BY_PHONE_NUMBER_API_ENDPOINT = 'confirmation/agents/orders_2b_confirmed/';

export const getNextOrder = async () => {
    try {
        return await RequestService.getRequest<Order>(CALLSESSION_NEXT_ORDER_API_ENDPOINT);
    } catch {
        return null;
    }
};

export const getAssignedOrderByClientPhoneNumber = async (phoneNumber: string) => {

    const statues: OrderStatus[] = [
        OrderStatus.PENDING,
        OrderStatus.CALLBACK,
        OrderStatus.POSTPONED,
        OrderStatus.CANCELLED
    ];

    try {
        const res = await RequestService.getRequest<any[]>(CALLSESSION_LIST_ORDERS_BY_PHONE_NUMBER_API_ENDPOINT, {
            params: {
                'status__in': statues.join(','),
                'customer_phone__icontains': phoneNumber,
            }
        });

        return res.map(obj => ({
            ...obj,
            status: obj.status === 'Pending' ? OrderStatus.PENDING :
                    obj.status === 'Postponed' ? OrderStatus.POSTPONED :
                    obj.status === 'Callback' ? OrderStatus.CALLBACK :
                    obj.status === 'Cancelled' ? OrderStatus.CANCELLED :
                    null,
        }) as Order) 
    } catch {
        return [];
    }
}