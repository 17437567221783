export const userConstants = {
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SENTUSER: "USERS_REGISTER_SENTUSER",

  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",

  UPDATE_STORE_SUCCESS: "UPDATE_STORE_SUCCESS",
  UPDATE_STORE_REQUEST: "UPDATE_STORE_REQUEST",
  UPDATE_STORE_FAILURE: "UPDATE_STORE_FAILURE",

  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  GET_USER_REQUEST: "GET_USER_REQUEST",

  GET_STOREINFO_SUCCESS: "GET_STORE_INFO_SUCCESS",
  GET_STOREINFO_FAILURE: "GET_STORE_INFO_FAILURE",
  GET_STOREINFO_REQUEST: "GET_STORE_INFO_REQUEST",

  UPLOAD_PICTURE_REQUEST: "UPLOAD_PICTURE_REQUEST",
  UPLOAD_PICTURE_SUCCESS: "UPLOAD_PICTURE_SUCCESS",
  UPLOAD_PICTURE_FAILURE: "UPLOAD_PICTURE_FAILURE",

  UPLOAD_USER_PICTURE_REQUEST: "UPLOAD_USER_PICTURE_REQUEST",
  UPLOAD_USER_PICTURE_SUCCESS: "UPLOAD_USER_PICTURE_SUCCESS",
  UPLOAD_USER_PICTURE_FAILURE: "UPLOAD_USER_PICTURE_FAILURE",

  RESET_SMS_REQUEST: "RESET_SMS_REQUEST",
  RESET_SMS_SUCCESS: "RESET_SMS_SUCCESS",
  RESET_SMS_FAILURE: "RESET_SMS_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  SET_IS_CANARY: "SET_IS_CANARY",

  LOGOUT: "USERS_LOGOUT",

  GET_DATA_USER_REQUEST: "GET_DATA_USER_REQUEST",
  GET_DATA_USER_SUCCESS: "GET_DATA_USER_SUCCESS",
  GET_DATA_USER_FAILURE: "GET_DATA_USER_FAILURE",
};
