import Pagination from "@mui/material/Pagination";
import styles from "./PaginationBar.module.scss";

const PaginationBar = (props) => {
  const { totalCount, totalPages, page, resultsLength, handlePageChange } =
    props;

  const firstRange = (+page - 1) * resultsLength + 1;
  const lastRange =
    (+page - 1) * resultsLength + resultsLength > totalCount
      ? totalCount
      : (+page - 1) * resultsLength + resultsLength;

  return (
    <div className={styles.pagination_container}>
      <div className={styles.pagination_info}>
        <span>
          Showing {firstRange}-{lastRange} of {totalCount}
        </span>
      </div>
      <div className="pagination_bar">
        <Pagination
          count={totalPages || 10}
          page={page}
          onChange={handlePageChange}
          shape="rounded"
          color="primary"
        />
      </div>
    </div>
  );
};

export default PaginationBar;
