import axios from "../../http-common";
import Cookies from "js-cookie";

const getAllOrders = async (page, filterParams) => {
  return await axios.get(`confirmation/orders_2b_confirmed/?page=${page}${filterParams}`, {
    headers: { Authorization: `token ${Cookies.get("auth")}` },
  });
};
const getAllAgents = () => {
  return axios.get("confirmation/confirmation_agents/", {
    headers: { Authorization: `token ${Cookies.get("auth")}` },
  });
};
const assignOrdersToAgent = (ordersArray, agentId, status, postponed_to, query) => {
  let requestBody;
  if (status) {
    if (postponed_to) {
      requestBody = query
        ? {
            confirmation_agent: agentId,
            new_status: status,
            postponed_to: postponed_to,
          }
        : {
            orders_2b_confirmed: ordersArray,
            confirmation_agent: agentId,
            new_status: status,
            postponed_to: postponed_to,
          };
    } else {
      requestBody = query
        ? {
            confirmation_agent: agentId,
            new_status: status,
          }
        : {
            orders_2b_confirmed: ordersArray,
            confirmation_agent: agentId,
            new_status: status,
          };
    }
  } else {
    requestBody = query
      ? {
          confirmation_agent: agentId,
        }
      : {
          orders_2b_confirmed: ordersArray,
          confirmation_agent: agentId,
        };
  }

  return axios.post(`confirmation/orders_2b_confirmed/update_agent_assignments/${query}`, requestBody, {
    headers: { Authorization: `token ${Cookies.get("auth")}` },
  });
};

const getExportCSV = (query) => {
  return axios.get(`confirmation/orders_2b_confirmed/export/${query}`, {
    headers: { Authorization: `token ${Cookies.get("auth")}` },
  });
};
const Services = {
  getAllOrders,
  getAllAgents,
  assignOrdersToAgent,
  getExportCSV,
};
export default Services;
