import React, { useState } from "react";

export type ModelActionImportance = 'high' | 'normal' | 'low';

const ACTION_IMPORTANCE_CLASS_MAP: Record<ModelActionImportance, string> = {
    'high': 'md-btn-important',
    'normal': 'md-btn-primary',
    'low': 'md-btn-secondary',
};

export interface ModalAction {
    label: string;
    action: () => void;
    importance?:  ModelActionImportance;
    disabled?: boolean | (() => boolean);
}

export interface BaseModalProps {
    title?: string;
    actions?: ModalAction[];
    children?: JSX.Element;
    onHide?: () => void;
}

interface ModalActionsSectionProps {
    actions: ModalAction[];
}

const ModalActionsSection = ({ actions }: ModalActionsSectionProps) => {

    const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>, action: () => void) => {
        event.stopPropagation();
        action();
    }

    return (
        <div className="modal-actions-section">
            { actions.map(({ label, action, importance, disabled=false }) => {
                return (
                    <button
                        className={`md-btn ${importance ? ACTION_IMPORTANCE_CLASS_MAP[importance] : 'md-btn-primary'}`}
                        onClick={ (e) => handleActionClick(e, action) }
                        disabled={ typeof disabled === 'function' ? disabled() : disabled }
                    >
                        { label }
                    </button>
                );
            }) }
        </div>
    );
}

const BaseModal = ({ title, actions, children, onHide }: BaseModalProps) => {
    
    const [close, setClose] = useState(false);
    const closeBaseModal = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setClose(true);
        onHide && onHide();
    }

    return (
        <>
        { !close && <div className="modal-root-backdrop" onClick={closeBaseModal}>
            <div className="modal-container" onClick={(e) => e.stopPropagation()}>
                { title && <span className="modal-title">{ title }</span> }
                { children }
                { actions &&  <ModalActionsSection actions={actions} />}
            </div>
        </div> }
        </>
    );
};

export default BaseModal;