export const callConstants = {
    
    START_CALL_REQUEST: 'START_CALL_REQUEST',
    START_CALL_SUCCESS: 'START_CALL_SUCCESS',
    START_CALL_FAILURE: 'START_CALL_FAILURE',

    PAUSE_CALL_REQUEST: 'PAUSE_CALL_REQUEST',
    PAUSE_CALL_SUCCESS: 'PAUSE_CALL_SUCCESS',
    PAUSE_CALL_FAILURE: 'PAUSE_CALL_FAILURE',
    
    RESUME_CALL_REQUEST: 'RESUME_CALL_REQUEST',
    RESUME_CALL_SUCCESS: 'RESUME_CALL_SUCCESS',
    RESUME_CALL_FAILURE: 'RESUME_CALL_FAILURE',

    HANGUP_REQUEST: 'HANGUP_REQUEST',
    HANGUP_SUCCESS: 'HANGUP_SUCCESS',
    HANGUP_FAILURE: 'HANGUP_FAILURE',

    SET_CALL_DURATION: 'SET_CALL_DURATION',

    
}